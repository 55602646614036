import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// เพิ่มการกำหนด CSS ลงไปใน document โดยตรง
const injectGlobalStyles = () => {
  // ตรวจสอบว่า style นี้มีอยู่แล้วหรือไม่
  const styleId = 'sweet-alert-custom-styles';
  if (document.getElementById(styleId)) return;
  
  const styleTag = document.createElement('style');
  styleTag.id = styleId;
  styleTag.innerHTML = `
    .swal2-popup {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    .swal2-title {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    .swal2-html-container {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    .swal2-confirm {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    .swal2-cancel {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    .swal2-footer {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
    button.swal2-confirm, button.swal2-cancel {
      font-family: 'Noto Sans Thai', sans-serif !important;
    }
  `;
  
  document.head.appendChild(styleTag);
};

// เรียกใช้ฟังก์ชันเพื่อเพิ่ม styles
injectGlobalStyles();

// กำหนดค่า default
const defaultParams = {
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  customClass: {
    popup: 'custom-swal-popup',
    title: 'custom-swal-title',
    htmlContainer: 'custom-swal-html',
    confirmButton: 'custom-swal-button',
    cancelButton: 'custom-swal-button',
    footer: 'custom-swal-footer'
  }
};

// สร้าง instance ของ SweetAlert2 ที่มี React content
const MySwal = withReactContent(Swal);

// Override ฟังก์ชัน fire ของ MySwal เพื่อใช้ defaultParams ทุกครั้ง
const originalFire = MySwal.fire;
MySwal.fire = function(...args) {
  // รวม defaultParams เข้ากับ params ที่ส่งมา
  if (args.length === 1 && typeof args[0] === 'object') {
    return originalFire.call(this, {
      ...defaultParams,
      ...args[0]
    });
  }
  return originalFire.apply(this, args);
};

// สร้างฟังก์ชันที่ใช้งานบ่อย
export const showSuccessAlert = (title, text = '') => {
  return MySwal.fire({
    title,
    text,
    icon: 'success',
    confirmButtonText: 'ตกลง',
    confirmButtonColor: '#3B82F6'
  });
};

export const showErrorAlert = (title, text = '') => {
  return MySwal.fire({
    title,
    text,
    icon: 'error',
    confirmButtonText: 'ตกลง',
    confirmButtonColor: '#3B82F6'
  });
};

export const showConfirmAlert = (title, text = '') => {
  return MySwal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'ใช่, ดำเนินการ!',
    confirmButtonColor: '#3B82F6',
    cancelButtonText: 'ยกเลิก',
  });
};

export const showDeleteConfirmAlert = (title, text = "คุณจะไม่สามารถย้อนกลับได้!") => {
  return MySwal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'ใช่, ลบเลย!',
    confirmButtonColor: '#3B82F6',
    cancelButtonText: 'ยกเลิก',
  });
};

// เพิ่มฟังก์ชันใหม่สำหรับแสดงข้อความประเภท Info
export const showInfoAlert = (title, text = '', options = {}) => {
  return MySwal.fire({
    title,
    html: text,
    icon: 'info',
    confirmButtonText: 'ตกลง',
    confirmButtonColor: '#3B82F6',
    ...options
  });
};

// กำหนด Mixin สำหรับ Toast
export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  customClass: {
    popup: 'custom-swal-popup',
    title: 'custom-swal-title',
    htmlContainer: 'custom-swal-html'
  },
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

export default MySwal;