import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Calendar, Clock, User as UserIcon } from 'lucide-react';

const UserActivityTimelineView = ({ memberId, startDate, endDate }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // สร้าง fetchUserLogs ด้วย useCallback
  const fetchUserLogs = useCallback(async () => {
    if (!memberId) return;
    
    setLoading(true);
    try {
      const token = localStorage.getItem('admin_token');
      
      const params = {
        member_id: memberId,
        limit: 200 // ดึงข้อมูลจำนวนมาก
      };
      
      // เพิ่มพารามิเตอร์วันที่ถ้ามีการระบุ
      if (startDate) params.start_date = startDate;
      if (endDate) params.end_date = endDate;
      
      console.log("Fetching logs with params:", params);
      
      const response = await axios.get('/activity/admin/logs', {
        headers: { Authorization: `Bearer ${token}` },
        params
      });
      
      console.log("Logs response:", response.data);
      setLogs(response.data.data || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching user logs:', error);
      setError('ไม่สามารถดึงข้อมูลกิจกรรมผู้ใช้ได้');
    } finally {
      setLoading(false);
    }
  }, [memberId, startDate, endDate]);
  
  useEffect(() => {
    fetchUserLogs();
  }, [fetchUserLogs]);
  
  // กลุ่ม log ตาม session หรือตามวันที่
  const groupedLogs = logs.reduce((groups, log) => {
    // ใช้เฉพาะส่วนวันที่จาก event_time
    const date = new Date(log.event_time).toISOString().split('T')[0];
    if (!groups[date]) groups[date] = [];
    groups[date].push(log);
    return groups;
  }, {});

  // คงไว้เนื่องจากเป็นการเปลี่ยนรูปแบบวันที่ให้อ่านง่ายขึ้น
  const formatThaiDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString;
    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    const thaiMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    return `${day} ${thaiMonths[month]} ${year}`;
  };

  // ฟังก์ชันแสดงเวลาโดยคำนึงถึง timezone
  const formatTime = (dateString) => {
    if (!dateString) return '-';
    
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return dateString;
      
      return date.toLocaleTimeString('th-TH', {
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return dateString;
    }
  };
  
  if (loading) {
    return (
      <div className="p-4 bg-white rounded shadow">
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
          <span className="ml-3 text-gray-600">กำลังโหลดข้อมูล...</span>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="p-4 bg-white rounded shadow">
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
          <p className="font-medium">เกิดข้อผิดพลาด</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }
  
  if (logs.length === 0) {
    return (
      <div className="p-4 bg-white rounded shadow">
        <div className="text-center py-8 text-gray-500">
          ไม่พบข้อมูลกิจกรรมของผู้ใช้รหัส {memberId} ในช่วงเวลาที่เลือก
        </div>
      </div>
    );
  }
  
  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-2xl font-bold mb-6 flex items-center gap-2 border-b pb-4">
        <UserIcon className="h-6 w-6 text-indigo-600" />
        รายงานกิจกรรมของผู้ใช้ รหัสสมาชิก : {memberId}
      </h2>
      
      {Object.keys(groupedLogs).sort((a, b) => new Date(b) - new Date(a)).map(date => (
        <div key={date} className="mb-8">
          <div className="mb-3 text-lg font-medium text-gray-700 flex items-center gap-2 bg-gray-50 p-2 rounded">
            <Calendar className="h-5 w-5 text-indigo-600" />
            <span>{formatThaiDate(date)}</span>
          </div>
          <div className="border-l-2 border-indigo-300 pl-4 space-y-4">
            {groupedLogs[date].map((log, index) => {
              // แปลง action_details และ additional_info
              const additionalInfo = log.additional_info ? (typeof log.additional_info === 'string' ? JSON.parse(log.additional_info) : log.additional_info) : {};
              const actionDetails = log.action_details ? (typeof log.action_details === 'string' ? JSON.parse(log.action_details) : log.action_details) : {};
              
              // สร้างข้อความแสดงรายละเอียด
              let activityDetails = '';
              
              if (log.event_type === 'page_view') {
                activityDetails = `เข้าชมหน้า "${log.page_url}"`;
                if (actionDetails.title) {
                  activityDetails += ` (${actionDetails.title})`;
                }
              } else if (log.event_type === 'login') {
                activityDetails = `เข้าสู่ระบบ`;
                if (actionDetails.method) {
                  activityDetails += ` - วิธี: ${actionDetails.method}`;
                }
              } else if (log.event_type === 'logout') {
                activityDetails = `ออกจากระบบ`;
                if (additionalInfo.session_duration) {
                  const minutes = Math.floor(additionalInfo.session_duration / 60);
                  const seconds = additionalInfo.session_duration % 60;
                  activityDetails += ` (ใช้งาน ${minutes} นาที ${seconds} วินาที)`;
                }
              } else if (log.event_type === 'file_upload') {
                activityDetails = `อัปโหลดไฟล์`;
                if (actionDetails.filename) {
                  activityDetails += `: ${actionDetails.filename}`;
                }
              } else {
                activityDetails = log.event_type;
              }
              
              return (
                <div key={log.id} className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:border-indigo-100">
                  <div className="flex items-center gap-2 mb-2">
                    <Clock className="h-4 w-4 text-indigo-500" />
                    <span className="text-sm text-gray-600 font-medium">
                      {formatTime(log.event_time)}
                    </span>
                    <span className={`ml-2 px-2 py-1 text-xs rounded-full 
                      ${log.event_type === 'login' ? 'bg-green-100 text-green-800' : 
                      log.event_type === 'logout' ? 'bg-yellow-100 text-yellow-800' : 
                      log.event_type === 'page_view' ? 'bg-blue-100 text-blue-800' : 
                      log.event_type === 'file_upload' ? 'bg-purple-100 text-purple-800' : 
                      log.event_type === 'error' ? 'bg-red-100 text-red-800' : 
                      'bg-gray-100 text-gray-800'}`}>
                      {log.event_type}
                    </span>
                    {log.status && (
                      <span className={`ml-auto px-2 py-1 text-xs rounded-full 
                        ${log.status === 'success' ? 'bg-green-100 text-green-800' : 
                        'bg-red-100 text-red-800'}`}>
                        {log.status}
                      </span>
                    )}
                  </div>
                  <div className="text-sm text-gray-700 mt-1">
                    {activityDetails}
                  </div>
                  
                  {/* Device info */}
                  {log.device_type && (
                    <div className="mt-2 text-xs text-gray-500 flex items-center">
                      <span className="inline-block mr-1">
                        {log.device_type === 'mobile' ? '📱' : 
                         log.device_type === 'tablet' ? '📱' : '💻'}
                      </span>
                      {log.browser} / {log.os} | {log.ip_address}
                    </div>
                  )}
                  
                  {/* Additional details */}
                  {(Object.keys(actionDetails).length > 0 || Object.keys(additionalInfo).length > 0) && (
                    <details className="mt-2">
                      <summary className="text-xs text-indigo-600 cursor-pointer">รายละเอียดเพิ่มเติม</summary>
                      <div className="mt-2 p-2 bg-gray-50 rounded text-xs">
                        {Object.keys(actionDetails).length > 0 && (
                          <div className="mb-2">
                            <span className="font-medium">Action Details:</span>
                            <pre className="whitespace-pre-wrap mt-1">{JSON.stringify(actionDetails, null, 2)}</pre>
                          </div>
                        )}
                        {Object.keys(additionalInfo).length > 0 && (
                          <div>
                            <span className="font-medium">Additional Info:</span>
                            <pre className="whitespace-pre-wrap mt-1">{JSON.stringify(additionalInfo, null, 2)}</pre>
                          </div>
                        )}
                      </div>
                    </details>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserActivityTimelineView;