import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, MoveVertical, AlignLeft, AlignCenter, AlignRight, Type, Paperclip, Upload, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm'; 
import { markdownComponents, getButtonGroups } from '../../lib/markdownUtils';
import MySwal, { Toast, showErrorAlert, showConfirmAlert, showInfoAlert } from '../../lib/sweetAlertConfig';

// Skeleton Loading Components
// Table Row Skeleton for desktop view
const TableRowSkeleton = () => (
  <div className="flex items-center border-b hover:bg-gray-50 animate-pulse">
    <div className="w-[10%] px-3 py-4 whitespace-nowrap text-center">
      <div className="flex justify-center">
        <div className="h-5 w-5 bg-gray-200 rounded"></div>
      </div>
    </div>
    <div className="w-[20%] px-6 py-4 whitespace-nowrap text-center">
      <div className="h-4 bg-gray-200 rounded w-16 mx-auto"></div>
    </div>
    <div className="w-[50%] px-6 py-4 whitespace-nowrap">
      <div className="h-4 bg-gray-200 rounded w-36"></div>
    </div>
    <div className="w-[20%] px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500 flex justify-center">
      <div className="flex space-x-3">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  </div>
);

// Card Skeleton for mobile view
const MobileCardSkeleton = () => (
  <div className="border rounded-lg overflow-hidden bg-white animate-pulse mb-3">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="h-4 bg-gray-200 rounded w-24"></div>
      <div className="h-4 w-4 bg-gray-200 rounded"></div>
    </div>
    <div className="p-4">
      <div className="h-5 bg-gray-200 rounded w-32 mb-2"></div>
      <div className="h-4 bg-gray-200 rounded w-20"></div>
    </div>
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-8 bg-gray-200 rounded w-16"></div>
      <div className="h-8 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const AnnouncementManagement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    title: '',
    content: '',
    attachments: []
  });
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  // Fetch announcements from API
  const fetchAnnouncements = useCallback(async () => {
    try {
      setLoading(true); // Start loading state when fetching data
      const response = await axios.get('/announcements');
      // เปลี่ยนการเรียงลำดับจาก น้อยไปมาก เป็น มากไปน้อย (จาก order_position มากที่สุดขึ้นบน)
      const sortedAnnouncements = [...response.data].sort((a, b) => {
        if (a.order_position !== undefined && b.order_position !== undefined) {
          // เรียงจากมากไปน้อย (เปลี่ยนจาก a - b เป็น b - a)
          return b.order_position - a.order_position;
        }
        // ถ้าไม่มี order_position ให้เรียงตาม id จากใหม่ไปเก่า (เปลี่ยนจาก a - b เป็น b - a)
        return b.id - a.id;
      });
      setAnnouncements(sortedAnnouncements);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      showErrorAlert('ไม่สามารถโหลดข้อมูลประกาศได้', 'กรุณาลองใหม่อีกครั้ง');
    } finally {
      // Add a slight delay to make the skeleton visible long enough
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, []);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const resetForm = () => {
    setEditingAnnouncement(null);
    setFormData({
      date: '',
      title: '',
      content: '',
      attachments: [] // เพิ่ม reset attachments
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // ตรวจสอบและแปลงวันที่ให้ถูกต้อง
      let formattedDate;
      if (formData.date) {
        const dateObj = new Date(formData.date);
        formattedDate = format(dateObj, 'yyyy-MM-dd');
      } else {
        formattedDate = format(new Date(), 'yyyy-MM-dd'); // ใช้วันที่ปัจจุบันถ้าไม่มีวันที่
      }

      // หา order_position สูงสุดจากประกาศที่มีอยู่
      let nextOrderPosition = 1;
      if (announcements.length > 0) {
        const maxOrderPos = Math.max(...announcements.map(item => 
          item.order_position !== null && item.order_position !== undefined ? Number(item.order_position) : 0
        ));
        // เพิ่มค่า order_position ให้มากกว่าค่าที่มีอยู่ เพื่อให้รายการใหม่อยู่ด้านบนสุด
        nextOrderPosition = maxOrderPos + 1;
      }

      console.log('Next order position calculated:', nextOrderPosition);

      // ตรวจสอบ attachments ก่อนส่งไปที่ API
      console.log('Attachments before submit:', formData.attachments);
      
      const submissionData = {
        title: formData.title,
        date: formattedDate,
        content: formData.content,
        order_position: editingAnnouncement ? (editingAnnouncement.order_position || 0) : nextOrderPosition,
        attachments: formData.attachments || []
      };

      console.log('Submitting data:', submissionData);

      const url = editingAnnouncement
        ? `/announcements/${editingAnnouncement.id}`
        : '/announcements';

      const method = editingAnnouncement ? 'PUT' : 'POST';

      const response = await axios({
        method,
        url,
        data: submissionData
      });

      console.log('API response:', response.data);

      if (response.status === 200 || response.status === 201) {
        fetchAnnouncements();
        setIsFormVisible(false);
        resetForm();
        
        Toast.fire({
          icon: 'success',
          title: editingAnnouncement ? 'อัพเดตประกาศเรียบร้อยแล้ว' : 'เพิ่มประกาศเรียบร้อยแล้ว'
        });
      } else {
        showErrorAlert('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกประกาศได้');
      }
    } catch (error) {
      console.error('Error saving announcement:', error);
      const errorMessage = error.response?.data?.message || 'ไม่สามารถบันทึกประกาศได้';
      showErrorAlert('เกิดข้อผิดพลาด!', errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    showConfirmAlert(
      'คุณแน่ใจหรือไม่?', 
      'คุณจะไม่สามารถย้อนกลับได้!'
    ).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const token = localStorage.getItem("admin_token");
          
          const response = await axios.delete(`/announcements/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            fetchAnnouncements();
            
            Toast.fire({
              icon: 'success',
              title: 'ลบประกาศเรียบร้อยแล้ว'
            });
          }
        } catch (error) {
          console.error('Error deleting announcement:', error);
          showErrorAlert('เกิดข้อผิดพลาด!', 'ไม่สามารถลบประกาศได้.');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (announcement) => {
    setEditingAnnouncement(announcement);
    setFormData({
      date: new Date(announcement.date),
      title: announcement.title,
      content: announcement.content || announcement.description,
      attachments: announcement.attachments ? 
        (typeof announcement.attachments === 'string' ? 
          JSON.parse(announcement.attachments) : announcement.attachments) : []
    });
    setIsFormVisible(true);
  };

  // ฟังก์ชันเมื่อมีการสลับลำดับโดยการลาก
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(announcements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    // อัปเดตลำดับหลังการลาก - เปลี่ยนค่าเพื่อให้เรียงจากมากไปน้อย
    // กำหนด order_position ให้รายการบนสุดมีค่าสูงสุด
    const maxOrder = items.length;
    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: maxOrder - index // รายการแรก = ค่าสูงสุด, รายการสุดท้าย = 1
    }));
    
    setAnnouncements(updatedItems);
    setIsOrderChanged(true);
  };

  // ฟังก์ชันบันทึกการเปลี่ยนแปลงลำดับ
  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("admin_token");
      if (!token) {
        throw new Error("ไม่พบ Admin Token");
      }

      const orderData = announcements.map((item, index) => ({
        id: item.id,
        order_position: announcements.length - index // เรียงจากบนลงล่าง
      }));

      const response = await axios.post('/announcements/reorder', {
        announcements: orderData
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setIsOrderChanged(false);
        
        // เปลี่ยนจาก showSuccessAlert เป็น Toast.fire
        Toast.fire({
          icon: 'success',
          title: 'บันทึกลำดับประกาศเรียบร้อยแล้ว'
        });
      }
    } catch (error) {
      console.error("Error saving order:", error);
      
      if (error.response && error.response.status === 401) {
        showInfoAlert("เซสชันหมดอายุ", "กรุณาเข้าสู่ระบบใหม่อีกครั้ง").then(() => {
          localStorage.removeItem("admin_token");
          navigate('/adminlogin');
        });
      } else {
        showErrorAlert(
          "เกิดข้อผิดพลาด", 
          error.response?.data?.message || "ไม่สามารถบันทึกลำดับประกาศได้"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // ฟังก์ชันเพิ่มรูปแบบ Markdown ลงในเนื้อหา
const insertMarkdown = (example) => {
  const textArea = document.getElementById('announcement-content');
  if (!textArea) return;
  
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;
  const text = formData.content;
  const beforeText = text.substring(0, start);
  const selectedText = text.substring(start, end);
  const afterText = text.substring(end);
  
  let newText = '';
  let placeCursor = 0;
  
  // กรณีเป็นปุ่มจัดเรียง
  if (example.prefix.includes('align')) {
    if (selectedText) {
      newText = beforeText + example.prefix + selectedText + example.suffix + afterText;
      placeCursor = beforeText.length + example.prefix.length + selectedText.length; // ตำแหน่งหลังข้อความที่เลือก
    } else {
      newText = beforeText + example.prefix + "ข้อความที่ต้องการจัดเรียง" + example.suffix + afterText;
      placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
    }
  }
  // กรณีเป็นปุ่มลิงก์
  else if (example.middle) {
    if (selectedText) {
      newText = beforeText + example.prefix + selectedText + example.middle + "URL" + example.suffix + afterText;
      placeCursor = beforeText.length + example.prefix.length + selectedText.length + example.middle.length;
    } else {
      newText = beforeText + example.prefix + "ข้อความ" + example.middle + "URL" + example.suffix + afterText;
      placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
    }
  } 
  // กรณีเป็นปุ่มอื่นๆ 
  else {
    if (selectedText) {
      newText = beforeText + example.prefix + selectedText + (example.suffix || '') + afterText;
      placeCursor = beforeText.length + example.prefix.length + selectedText.length; // ตำแหน่งหลังข้อความที่เลือก
    } else {
      newText = beforeText + example.prefix + "ข้อความ" + (example.suffix || '') + afterText;
      placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
    }
  }
  
  setFormData({ ...formData, content: newText });
  
  // โฟกัสที่ textarea และตั้งค่าตำแหน่งเคอร์เซอร์หลังจากแทรก
  setTimeout(() => {
    textArea.focus();
    
    if (selectedText) {
      // ถ้ามีข้อความที่เลือก ให้วางเคอร์เซอร์ไว้หลังข้อความที่เลือก
      textArea.setSelectionRange(placeCursor, placeCursor);
    } else {
      // ถ้าไม่มีข้อความที่เลือก ให้เลือกข้อความ placeholder
      const startPos = beforeText.length + example.prefix.length;
      const endPos = startPos + "ข้อความ".length;
      textArea.setSelectionRange(startPos, endPos);
    }
  }, 0);
};

  // ฟังก์ชันสำหรับการอัพโหลดไฟล์
  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    
    const formData = new FormData();
    formData.append('file', files[0]);
    
    try {
      // แสดง loading
      const loadingSwal = MySwal.fire({
        title: 'กำลังอัพโหลด...',
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        }
      });
      
      const response = await axios.post('/announcements/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      loadingSwal.close();
      
      if (response.status === 200) {
        setFormData(prev => ({
          ...prev,
          attachments: [...prev.attachments, {
            path: response.data.filePath,
            name: response.data.fileName
          }]
        }));
        
        Toast.fire({
          icon: 'success',
          title: 'อัพโหลดสำเร็จ'
        });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      showErrorAlert('เกิดข้อผิดพลาด', 'ไม่สามารถอัพโหลดไฟล์ได้');
    }
  };

  // ฟังก์ชันสำหรับการลบไฟล์ที่แนบ
  const handleRemoveFile = (index) => {
    setFormData(prev => {
      const newAttachments = [...prev.attachments];
      newAttachments.splice(index, 1);
      return {
        ...prev,
        attachments: newAttachments
      };
    });
  };

  // กรองรายการประกาศตามคำค้นหา
  const filteredAnnouncements = announcements.filter((announcement) =>
    announcement.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // นำเข้ากลุ่มปุ่ม
  const buttonGroups = getButtonGroups();

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <div className="mb-8 flex justify-center items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">จัดการประกาศ</h1>
          <p className="text-gray-600 mt-2">เพิ่ม ลบ และแก้ไขประกาศ</p>
        </div>
      </div>

      <Card className="p-6 mb-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-1">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="ค้นหาประกาศ..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2 justify-between sm:justify-end">
            {isOrderChanged && (
              <button
                onClick={handleSaveOrder}
                className="flex items-center gap-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base"
              >
                <MoveVertical className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1" />
                <span className="hidden sm:inline">บันทึกลำดับ</span>
                <span className="sm:hidden">บันทึก</span>
              </button>
            )}
            <button
              onClick={() => {
                resetForm();
                setIsFormVisible(true);
              }}
              className="flex items-center gap-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base"
            >
              <Plus className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
              <span className="hidden sm:inline">เพิ่มประกาศ</span>
              <span className="sm:hidden">เพิ่ม</span>
            </button>
          </div>
        </div>

        {/* Inline Form สำหรับเพิ่ม/แก้ไขประกาศ */}
        {isFormVisible && (
          <form onSubmit={handleSubmit} className="mb-6 border p-4 rounded-md text-start">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">วันที่</label>
              <DatePicker
                selected={formData.date ? new Date(formData.date) : null}
                onChange={(date) => setFormData({ ...formData, date: date })}
                dateFormat="dd/MM/yyyy"
                className="w-full border rounded-md px-3 py-2"
                placeholderText="เลือกวันที่..."
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">เรื่อง</label>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2"
                placeholder="ระบุหัวข้อ..."
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">เนื้อหา (รองรับ Markdown)</label>
              
              {/* กลุ่มปุ่มการจัดรูปแบบ */}
              <div className="mb-2">
                <div className="flex flex-wrap gap-1 mb-2">
                  {/* กลุ่มปุ่มหัวข้อ */}
                  <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                    {buttonGroups.heading.map((example, index) => (
                      <button
                        key={`heading-${index}`}
                        type="button"
                        onClick={() => insertMarkdown(example)}
                        className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                        title={`เพิ่ม${example.label}`}
                      >
                        <Type className="h-3 w-3 mr-1" />
                        {example.label}
                      </button>
                    ))}
                  </div>
                  
                  {/* กลุ่มปุ่มหัวข้อกลาง */}
                  <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                    {buttonGroups.centered.map((example, index) => (
                      <button
                        key={`centered-${index}`}
                        type="button"
                        onClick={() => insertMarkdown(example)}
                        className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                        title={`เพิ่ม${example.label}`}
                      >
                        <AlignCenter className="h-3 w-3 mr-1" />
                        {example.label}
                      </button>
                    ))}
                  </div>
                  
                  {/* กลุ่มปุ่มตัวหนา/ตัวเอียง */}
                  <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                    {buttonGroups.format.map((example, index) => (
                      <button
                        key={`format-${index}`}
                        type="button"
                        onClick={() => insertMarkdown(example)}
                        className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                        title={`เพิ่ม${example.label}`}
                      >
                        {example.label}
                      </button>
                    ))}
                  </div>
                  
                  {/* กลุ่มปุ่มโครงสร้าง (รายการ, ลิงก์, อ้างอิง) */}
                  <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                    {buttonGroups.structure.map((example, index) => (
                      <button
                        key={`structure-${index}`}
                        type="button"
                        onClick={() => insertMarkdown(example)}
                        className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                        title={`เพิ่ม${example.label}`}
                      >
                        {example.label}
                      </button>
                    ))}
                  </div>
                  
                  {/* กลุ่มปุ่มการจัดวางข้อความ */}
                  <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                    {buttonGroups.alignment.map((example, index) => (
                      <button
                        key={`alignment-${index}`}
                        type="button"
                        onClick={() => insertMarkdown(example)}
                        className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                        title={`จัดข้อความ${example.label}`}
                      >
                        {example.label === "ชิดซ้าย" && <AlignLeft className="h-3 w-3 mr-1" />}
                        {example.label === "อยู่กลาง" && <AlignCenter className="h-3 w-3 mr-1" />}
                        {example.label === "ชิดขวา" && <AlignRight className="h-3 w-3 mr-1" />}
                        {example.label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            
              <textarea
                id="announcement-content"
                className="w-full border rounded-md px-3 py-2"
                placeholder="เนื้อหาประกาศ... (รองรับการใช้ Markdown และ HTML)"
                value={formData.content}
                onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                rows={8}
                required
              />

              {/* ปรับปรุงคำอธิบายใต้กล่องข้อความ */}
              <div className="mt-1 text-xs text-gray-500">
                <span>เคล็ดลับ: </span>
                <span>ใช้ Enter เพื่อขึ้นบรรทัดใหม่ตามปกติได้เลย</span>
              </div>
              
              {/* เพิ่มตัวอย่างการแสดงผล */}
              {formData.content && (
                <div className="mt-4">
                  <div className="text-sm font-medium text-gray-700 mb-2">ตัวอย่างการแสดงผล:</div>
                  <div className="border rounded-md p-4 bg-white">
                    <div className="markdown-body">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={markdownComponents}
                      >
                        {formData.content}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">ไฟล์แนบ</label>
              
              <div className="flex items-center gap-2 mb-2">
                <label className="flex items-center gap-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors cursor-pointer">
                  <Upload className="h-5 w-5" />
                  <span>อัพโหลดไฟล์</span>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                </label>
                <span className="text-xs text-gray-500">รองรับไฟล์ PDF, รูปภาพ, เอกสาร และอื่นๆ</span>
              </div>
              
              {/* แสดงรายการไฟล์ที่อัพโหลดแล้ว */}
              {formData.attachments && formData.attachments.length > 0 && (
                <div className="mt-2">
                  <h4 className="text-sm font-medium mb-1">ไฟล์ที่แนบ:</h4>
                  <ul className="space-y-2">
                    {formData.attachments.map((file, index) => (
                      <li key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded border">
                        <div className="flex items-center">
                          <Paperclip className="h-4 w-4 mr-2 text-gray-500" />
                          <span className="text-sm truncate max-w-[200px]">{file.name}</span>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
              >
                บันทึก
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsFormVisible(false);
                  resetForm();
                }}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      <Card className="p-6">
  {/* การแสดงผลแบบตารางบนจอใหญ่ (hidden บนมือถือ) */}
  <div className="min-w-full hidden sm:block">
    {loading ? (
      Array.from({ length: 5 }).map((_, index) => <TableRowSkeleton key={index} />)
    ) : (
      <>
        {/* ส่วนหัวตาราง */}
        <div className="flex bg-white border-b">
          <div className="w-[10%] px-3 py-3 text-center text-base font-medium text-gray-500 uppercase tracking-wider">ลำดับ</div>
          <div className="w-[20%] px-6 py-3 text-center text-base font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
          <div className="w-[50%] px-6 py-3 text-center text-base font-medium text-gray-500 uppercase tracking-wider">เรื่อง</div>
          <div className="w-[20%] px-6 py-3 text-center text-base font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
        </div>
        
        {/* เนื้อหาตารางแบบธรรมดา (แสดงเมื่อไม่มีรายการ) - Desktop */}
        {filteredAnnouncements.length === 0 ? (
          <div className="px-6 py-4 text-center">ไม่มีประกาศ</div>
        ) : (
          /* ส่วนเนื้อหาตารางแบบ Drag & Drop - Desktop */
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="announcements-list" type="ANNOUNCEMENT">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bg-white"
                >
                  {filteredAnnouncements.map((announcement, index) => (
                    <Draggable 
                      key={announcement.id.toString()} 
                      draggableId={announcement.id.toString()} 
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`flex items-center border-b hover:bg-gray-50 ${snapshot.isDragging ? 'bg-gray-100' : ''}`}
                        >
                          <div className="w-[10%] px-3 py-4 whitespace-nowrap text-center">
                            <div className="flex justify-center cursor-move">
                              <MoveVertical className="h-5 w-5 text-gray-500" />
                            </div>
                          </div>
                          <div className="w-[20%] px-6 py-4 whitespace-nowrap text-center">
                            {format(new Date(announcement.date), 'd/M/yy')}
                          </div>
                          <div className="w-[50%] px-6 py-4 whitespace-nowrap">
                            {announcement.title}
                          </div>
                          <div className="w-[20%] px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500 flex justify-center">
                            <button
                              onClick={() => handleEdit(announcement)}
                              className="text-blue-600 hover:text-blue-900 mr-3"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDelete(announcement.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    )}
  </div>

  {/* การแสดงผลแบบการ์ดสำหรับมือถือ (block เฉพาะบนมือถือ) */}
  <div className="block sm:hidden">
    {loading ? (
      Array.from({ length: 5 }).map((_, index) => <MobileCardSkeleton key={index} />)
    ) : filteredAnnouncements.length === 0 ? (
      <div className="p-4 text-center text-gray-500">ไม่มีประกาศ</div>
    ) : (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="mobile-announcements-list" type="MOBILE_ANNOUNCEMENT">
          {(provided) => (
            <div 
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="space-y-3"
            >
              {filteredAnnouncements.map((announcement, index) => (
                <Draggable 
                  key={announcement.id.toString()} 
                  draggableId={`mobile-${announcement.id}`} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'}`}
                    >
                      <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-600">
                          {format(new Date(announcement.date), 'd MMMM yyyy')}
                        </div>
                        <div {...provided.dragHandleProps} className="cursor-move">
                          <MoveVertical className="h-4 w-4 text-gray-500" />
                        </div>
                      </div>
                      <div className="p-4">
                        <h3 className="font-medium text-gray-800 mb-2">{announcement.title}</h3>
                        {announcement.attachments && announcement.attachments.length > 0 && (
                          <div className="flex items-center text-sm text-gray-500 mb-2">
                            <Paperclip className="h-3.5 w-3.5 mr-1" />
                            <span>{announcement.attachments.length} ไฟล์แนบ</span>
                          </div>
                        )}
                      </div>
                      <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                        <button
                          onClick={() => handleEdit(announcement)}
                          className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1.5 rounded-md text-sm"
                        >
                          <Edit2 className="h-3.5 w-3.5" />
                          แก้ไข
                        </button>
                        <button
                          onClick={() => handleDelete(announcement.id)}
                          className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1.5 rounded-md text-sm"
                        >
                          <Trash2 className="h-3.5 w-3.5" />
                          ลบ
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )}
  </div>
</Card>

    </div>
  );
};

export default AnnouncementManagement;
