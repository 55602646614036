import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card } from "../ui/card";
import {
  Plus,
  Search,
  Edit2,
  Trash2,
  ArrowLeft,
  MoveVertical,
  Upload,
  Download,
  File,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Toast } from "../../lib/sweetAlertConfig"; // เพิ่มการนำเข้า Toast

const MySwal = withReactContent(Swal);

// เพิ่ม Skeleton Components

// Table Row Skeleton สำหรับตารางปกติ
const TableRowSkeleton = () => (
  <div className="flex items-center border-b animate-pulse">
    <div className="w-[10%] px-3 py-4 text-center">
      <div className="flex justify-center">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </div>
    <div className="w-[20%] px-3 py-4 text-center">
      <div className="h-4 w-16 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[40%] px-3 py-4">
      <div className="flex items-center mb-1">
        <div className="h-5 w-5 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-5 w-32 bg-gray-200 rounded"></div>
      </div>
      <div className="ml-7 h-3 w-48 bg-gray-200 rounded"></div>
    </div>
    <div className="w-[15%] px-3 py-4 text-center">
      <div className="h-6 w-16 bg-gray-200 rounded mx-auto"></div>
    </div>
    <div className="w-[15%] px-3 py-4 text-center flex justify-center">
      <div className="h-5 w-5 bg-gray-200 rounded-full mr-3"></div>
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
    </div>
  </div>
);

// Mobile Card Skeleton
const MobileCardSkeleton = () => (
  <div className="border rounded-lg overflow-hidden animate-pulse mb-3">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="h-4 w-24 bg-gray-200 rounded"></div>
      <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
    </div>
    <div className="p-3">
      <div className="flex items-center mb-2">
        <div className="h-4 w-4 bg-gray-200 rounded-full mr-2 flex-shrink-0"></div>
        <div className="h-4 w-32 bg-gray-200 rounded"></div>
      </div>
      <div className="h-3 w-full bg-gray-200 rounded mb-2"></div>
      <div className="flex justify-start mt-2">
        <div className="h-5 w-24 bg-gray-200 rounded"></div>
      </div>
    </div>
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-6 w-16 bg-gray-200 rounded"></div>
      <div className="h-6 w-12 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const CompanyFormsManagement = () => {
  const [forms, setForms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingForm, setEditingForm] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    file: null,
    fileName: "",
  });
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // แก้ไข fetchForms function เพื่อเพิ่ม delay
  const fetchForms = useCallback(async () => {
    try {
      setLoading(true);
      // ใช้ token ที่มีอยู่ในระบบเพื่อเข้าถึง API
      const token = localStorage.getItem("admin_token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.get("/company-forms", { headers });
      // เรียงลำดับจาก order_position มากไปน้อย
      const sortedForms = [...response.data].sort((a, b) => {
        if (a.order_position !== undefined && b.order_position !== undefined) {
          return b.order_position - a.order_position;
        }
        return b.id - a.id;
      });

      setForms(sortedForms);
    } catch (error) {
      console.error("Error fetching forms:", error);

      // เพิ่มการตรวจสอบ unauthorized error
      if (error.response?.status === 401 || error.response?.status === 403) {
        MySwal.fire({
          title: "กรุณาเข้าสู่ระบบ",
          text: "คุณไม่ได้เข้าสู่ระบบหรือ session หมดอายุ",
          icon: "warning",
          confirmButtonText: "เข้าสู่ระบบ",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          navigate("/adminlogin");
        });
      } else {
        MySwal.fire({
          title: "เกิดข้อผิดพลาด!",
          text: "ไม่สามารถโหลดข้อมูลแบบฟอร์มได้",
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
      }
    } finally {
      // เพิ่ม delay เพื่อให้ skeleton แสดงได้นานพอ
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [navigate]);

  useEffect(() => {
    const adminToken = localStorage.getItem("admin_token");
    if (!adminToken) {
      MySwal.fire({
        title: "ไม่มีสิทธิ์เข้าถึง!",
        text: "กรุณาเข้าสู่ระบบผู้ดูแลระบบก่อนใช้งานส่วนนี้",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      }).then(() => {
        navigate("/adminlogin");
      });
      return;
    }

    fetchForms();
  }, [fetchForms, navigate]);

  const resetForm = () => {
    setEditingForm(null);
    setFormData({
      title: "",
      description: "",
      file: null,
      fileName: "",
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        file: file,
        fileName: file.name,
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setFormData({
        ...formData,
        file: file,
        fileName: file.name,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("admin_token");
      if (!token) {
        MySwal.fire({
          title: "Session หมดอายุ",
          text: "กรุณาเข้าสู่ระบบใหม่",
          icon: "warning",
          confirmButtonText: "เข้าสู่ระบบ",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          navigate("/adminlogin");
        });
        return;
      }

      // ตรวจสอบข้อมูลที่จำเป็น
      if (!formData.title) {
        throw new Error("กรุณากรอกชื่อแบบฟอร์ม");
      }

      if (!formData.file && !editingForm) {
        throw new Error("กรุณาอัปโหลดไฟล์แบบฟอร์ม");
      }

      // สร้าง FormData สำหรับการอัปโหลดไฟล์
      const submitData = new FormData();
      submitData.append("title", formData.title);
      submitData.append("description", formData.description || "");

      if (formData.file) {
        submitData.append("file", formData.file);
      }

      let response;
      if (editingForm) {
        response = await axios.put(`/company-forms/${editingForm.id}`, submitData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await axios.post("/company-forms", submitData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      if (response.status === 200 || response.status === 201) {
        await fetchForms();
        resetForm();
        setIsFormVisible(false);

        // เปลี่ยนจาก MySwal.fire เป็น Toast.fire
        Toast.fire({
          icon: 'success',
          title: editingForm ? 'อัปเดตแบบฟอร์มสำเร็จ' : 'เพิ่มแบบฟอร์มสำเร็จ'
        });
      }
    } catch (error) {
      console.error("Error saving form:", error);

      let errorMessage = "เกิดข้อผิดพลาดในการบันทึกข้อมูล";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      MySwal.fire({
        title: "เกิดข้อผิดพลาด!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await MySwal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'ใช่, ลบเลย!',
        cancelButtonText: 'ยกเลิก'
      });

      if (result.isConfirmed) {
        setLoading(true);
        const token = localStorage.getItem("admin_token");

        const response = await axios.delete(`/company-forms/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          fetchForms();

          // เปลี่ยนจาก MySwal.fire เป็น Toast.fire
          Toast.fire({
            icon: 'success',
            title: 'ลบแบบฟอร์มสำเร็จ'
          });
        } else {
          MySwal.fire(
            'เกิดข้อผิดพลาด!',
            'ไม่สามารถลบแบบฟอร์มได้',
            'error'
          );
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error deleting form:", error);

      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: "Session หมดอายุ",
          text: "กรุณาเข้าสู่ระบบใหม่",
          icon: "warning",
          confirmButtonText: "เข้าสู่ระบบ",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          navigate("/adminlogin");
        });
      } else {
        MySwal.fire(
          'เกิดข้อผิดพลาด!',
          'ไม่สามารถลบแบบฟอร์มได้',
          'error'
        );
      }
      setLoading(false);
    }
  };

  const handleEdit = (form) => {
    setEditingForm(form);
    setFormData({
      title: form.title,
      description: form.description || "",
      file: null,
      fileName: form.file_name,
    });
    setIsFormVisible(true);
  };

  const handleDownload = (form) => {
    const token =
      localStorage.getItem("token") || localStorage.getItem("admin_token");
    if (!token) {
      MySwal.fire("เกิดข้อผิดพลาด!", "กรุณาเข้าสู่ระบบก่อนดาวน์โหลด", "error", {
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    window.open(`/company-forms/download/${form.id}?token=${token}`, "_blank");
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(forms);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const maxOrder = items.length;
    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: maxOrder - index,
    }));

    setForms(updatedItems);
    setIsOrderChanged(true);
  };

  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("admin_token");
      if (!token) {
        MySwal.fire({
          title: "Session หมดอายุ",
          text: "กรุณาเข้าสู่ระบบใหม่",
          icon: "warning",
          confirmButtonText: "เข้าสู่ระบบ",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          navigate("/adminlogin");
        });
        return;
      }

      // เตรียมข้อมูลสำหรับอัพเดตลำดับ
      const orderData = forms.map((form, index) => ({
        id: form.id,
        order_position: index + 1
      }));

      await axios.post("/company-forms/reorder", { forms: orderData }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setIsOrderChanged(false);

      // เปลี่ยนจาก MySwal.fire เป็น Toast.fire
      Toast.fire({
        icon: 'success',
        title: 'บันทึกลำดับแบบฟอร์มเรียบร้อยแล้ว'
      });
    } catch (error) {
      console.error("Error saving order:", error);

      if (error.response && error.response.status === 401) {
        MySwal.fire({
          title: "Session หมดอายุ",
          text: "กรุณาเข้าสู่ระบบใหม่",
          icon: "warning",
          confirmButtonText: "เข้าสู่ระบบ",
          confirmButtonColor: "#3085d6",
        }).then(() => {
          navigate("/adminlogin");
        });
      } else {
        MySwal.fire({
          title: "เกิดข้อผิดพลาด!",
          text: error.response?.data?.message || "ไม่สามารถบันทึกลำดับแบบฟอร์มได้",
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const filteredForms = forms.filter((form) =>
    form.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate("/admin/dashboard/data_management")}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">
            จัดการแบบฟอร์มบริษัท
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            เพิ่ม ลบ และแก้ไขแบบฟอร์มของบริษัท
          </p>
        </div>
      </div>

      <Card className="p-6 mb-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-1">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="ค้นหาแบบฟอร์ม..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2 justify-between sm:justify-end">
            {isOrderChanged && (
              <button
                onClick={handleSaveOrder}
                disabled={loading}
                className={`flex items-center gap-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <MoveVertical className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1" />
                <span className="hidden sm:inline">บันทึกลำดับ</span>
                <span className="sm:hidden">บันทึก</span>
              </button>
            )}
            <button
              onClick={() => {
                resetForm();
                setIsFormVisible(true);
              }}
              disabled={loading}
              className={`flex items-center gap-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <Plus className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
              <span className="hidden sm:inline">เพิ่มแบบฟอร์ม</span>
              <span className="sm:hidden">เพิ่ม</span>
            </button>
          </div>
        </div>

        {isFormVisible && (
          <form
            onSubmit={handleSubmit}
            className="mb-6 border p-4 rounded-md text-start"
          >
            <div className="mb-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                ชื่อแบบฟอร์ม
              </label>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2 text-base"
                placeholder="ระบุชื่อแบบฟอร์ม..."
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                คำอธิบาย (ถ้ามี)
              </label>
              <textarea
                className="w-full border rounded-md px-3 py-2 text-base"
                placeholder="รายละเอียดเพิ่มเติมเกี่ยวกับแบบฟอร์ม..."
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                ไฟล์แบบฟอร์ม{" "}
                {editingForm
                  ? "(ไม่จำเป็นต้องอัปโหลดหากไม่ต้องการเปลี่ยน)"
                  : "(จำเป็น)"}
              </label>
              <div
                className={`border-2 border-dashed rounded-md p-6 flex flex-col items-center justify-center cursor-pointer
                  ${dragOver ? "border-blue-500 bg-blue-50" : "border-gray-300"}
                  ${
                    loading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:border-gray-400"
                  }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
              >
                {formData.file || formData.fileName ? (
                  <div className="flex flex-col items-center">
                    <File className="h-16 w-16 text-blue-500 mb-2" />
                    <p className="text-sm text-gray-700 mb-1">
                      {formData.file?.name || formData.fileName}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formData.file
                        ? `${(formData.file.size / 1024 / 1024).toFixed(2)} MB`
                        : ""}
                    </p>
                    <button
                      type="button"
                      className="mt-2 text-sm text-red-600 hover:text-red-800"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (editingForm) {
                          setFormData({
                            ...formData,
                            file: null,
                            fileName: editingForm.file_name,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            file: null,
                            fileName: "",
                          });
                        }
                      }}
                    >
                      ยกเลิกไฟล์
                    </button>
                  </div>
                ) : (
                  <>
                    <Upload className="h-12 w-12 text-gray-400 mb-3" />
                    <p className="text-gray-600 text-center mb-1">
                      วางไฟล์ที่นี่หรือคลิกเพื่อเลือกไฟล์
                    </p>
                    <p className="text-gray-400 text-sm text-center">
                      สูงสุด 10MB
                    </p>
                  </>
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                disabled={loading}
                className={`bg-green-600 text-white px-6 py-2.5 text-base font-medium rounded-md hover:bg-green-700 transition-colors ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "กำลังบันทึก..." : "บันทึก"}
              </button>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setIsFormVisible(false);
                  resetForm();
                }}
                className={`bg-gray-300 text-gray-800 px-6 py-2.5 text-base font-medium rounded-md hover:bg-gray-400 transition-colors ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      <Card className="p-3 sm:p-6">
        {loading ? (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ลำดับ
                  </div>
                  <div className="w-[20%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    วันที่อัพเดท
                  </div>
                  <div className="w-[40%] px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ชื่อแบบฟอร์ม / รายละเอียด
                  </div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ดาวน์โหลด
                  </div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    จัดการ
                  </div>
                </div>
                {[...Array(5)].map((_, index) => (
                  <TableRowSkeleton key={index} />
                ))}
              </div>
            </div>
            <div className="sm:hidden space-y-3">
              {[...Array(3)].map((_, index) => (
                <MobileCardSkeleton key={index} />
              ))}
            </div>
          </>
        ) : filteredForms.length === 0 ? (
          <div className="px-6 py-4 text-center">ไม่มีแบบฟอร์ม</div>
        ) : (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ลำดับ
                  </div>
                  <div className="w-[20%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    วันที่อัพเดท
                  </div>
                  <div className="w-[40%] px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ชื่อแบบฟอร์ม / รายละเอียด
                  </div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ดาวน์โหลด
                  </div>
                  <div className="w-[15%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    จัดการ
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="forms-list" type="FORM">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="bg-white"
                      >
                        {filteredForms.map((form, index) => (
                          <Draggable
                            key={form.id.toString()}
                            draggableId={form.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`flex items-center border-b hover:bg-gray-50 ${
                                  snapshot.isDragging ? "bg-gray-100" : ""
                                }`}
                              >
                                <div className="w-[10%] px-3 py-4 text-center">
                                  <div className="flex justify-center cursor-move">
                                    <MoveVertical className="h-5 w-5 text-gray-500" />
                                  </div>
                                </div>
                                <div className="w-[20%] px-3 py-4 text-center text-sm">
                                  {format(
                                    new Date(
                                      form.updated_at || form.created_at
                                    ),
                                    "d/M/yy"
                                  )}
                                </div>
                                <div className="w-[40%] px-3 py-4">
                                  <div className="flex items-center">
                                    <File className="h-5 w-5 text-gray-500 mr-2 flex-shrink-0" />
                                    <span className="text-sm text-gray-800">
                                      {form.title}
                                    </span>
                                  </div>
                                  {form.description && (
                                    <p className="text-xs text-gray-500 mt-1">
                                      {form.description}
                                    </p>
                                  )}
                                </div>
                                <div className="w-[15%] px-3 py-4 text-center">
                                  <button
                                    onClick={() => handleDownload(form)}
                                    className="inline-flex items-center px-3 py-1 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
                                  >
                                    <Download className="h-4 w-4 mr-1" />
                                    <span className="text-sm">
                                      {form.download_count || 0}
                                    </span>
                                  </button>
                                </div>
                                <div className="w-[15%] px-3 py-4 text-center flex justify-center">
                                  <button
                                    onClick={() => handleEdit(form)}
                                    className="text-blue-600 hover:text-blue-900 mr-3"
                                  >
                                    <Edit2 className="h-5 w-5" />
                                  </button>
                                  <button
                                    onClick={() => handleDelete(form.id)}
                                    className="text-red-600 hover:text-red-900"
                                  >
                                    <Trash2 className="h-5 w-5" />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="sm:hidden">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="mobile-forms-list" type="MOBILE_FORM">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="space-y-3"
                    >
                      {filteredForms.map((form, index) => (
                        <Draggable
                          key={form.id.toString()}
                          draggableId={`mobile-${form.id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`border rounded-lg overflow-hidden ${
                                snapshot.isDragging ? "bg-gray-100" : "bg-white"
                              }`}
                            >
                              <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                                <div className="text-xs font-medium text-gray-600">
                                  {format(
                                    new Date(
                                      form.updated_at || form.created_at
                                    ),
                                    "d MMM yyyy"
                                  )}
                                </div>
                                <div className="flex items-center">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="cursor-move"
                                  >
                                    <MoveVertical className="h-4 w-4 text-gray-500" />
                                  </div>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="flex items-center mb-2">
                                  <File className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0" />
                                  <h3 className="font-medium text-gray-800">
                                    {form.title}
                                  </h3>
                                </div>
                                {form.description && (
                                  <p className="text-xs text-gray-600 mb-2 line-clamp-2">
                                    {form.description}
                                  </p>
                                )}
                                <div className="flex justify-start mt-2">
                                  <button
                                    onClick={() => handleDownload(form)}
                                    className="inline-flex items-center px-3 py-1 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200 text-xs"
                                  >
                                    <Download className="h-3 w-3 mr-1" />
                                    ดาวน์โหลด ({form.download_count || 0})
                                  </button>
                                </div>
                              </div>
                              <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                                <button
                                  onClick={() => handleEdit(form)}
                                  className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                                >
                                  <Edit2 className="h-3 w-3" />
                                  แก้ไข
                                </button>
                                <button
                                  onClick={() => handleDelete(form.id)}
                                  className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                                >
                                  <Trash2 className="h-3 w-3" />
                                  ลบ
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default CompanyFormsManagement;
