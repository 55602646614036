import React, { useState, useEffect } from 'react';
import { Card } from "../ui/card";
import { Save, X, Eye, EyeOff } from 'lucide-react';

const UserModal = ({ isModalOpen, setIsModalOpen, formData, setFormData, handleSubmit, showPassword, setShowPassword, editingUser }) => {
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [useDefaultPassword, setUseDefaultPassword] = useState(false); // เพิ่มสถานะสำหรับการใช้รหัสผ่านเริ่มต้น
  const defaultPassword = "@Password123"; // กำหนดค่ารหัสผ่านเริ่มต้น

  // Reset errors and confirm password when modal opens/closes
  useEffect(() => {
    if (isModalOpen) {
      setPasswordError("");
      setConfirmPassword("");
      setConfirmPasswordError("");
      setUseDefaultPassword(false); // รีเซ็ตสถานะการใช้รหัสผ่านเริ่มต้น
    }
  }, [isModalOpen]);

  // ตรวจสอบความซับซ้อนของรหัสผ่าน
  const validatePassword = (password) => {
    // ถ้าเลือกใช้รหัสผ่านเริ่มต้น จะไม่ตรวจสอบความซับซ้อน
    if (useDefaultPassword) return true;
    
    if (!password) return true; // ถ้าไม่ได้กรอกรหัสผ่านและเป็นการแก้ไขข้อมูล ให้ผ่าน
    if (editingUser && !password) return true;
    
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/;
    
    if (!passwordRegex.test(password)) {
      setPasswordError("รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว");
      return false;
    }
    
    setPasswordError("");
    return true;
  };

  // ตรวจสอบการยืนยันรหัสผ่าน
  const validateConfirmPassword = () => {
    // ถ้าเลือกใช้รหัสผ่านเริ่มต้น จะไม่ตรวจสอบการยืนยันรหัสผ่าน
    if (useDefaultPassword) return true;
    
    if (!formData.password && editingUser) return true;
    
    if (formData.password !== confirmPassword) {
      setConfirmPasswordError("รหัสผ่านและรหัสผ่านยืนยันไม่ตรงกัน");
      return false;
    }
    
    setConfirmPasswordError("");
    return true;
  };

  // เมื่อเปลี่ยนสถานะการใช้รหัสผ่านเริ่มต้น
  const handleDefaultPasswordChange = () => {
    const newState = !useDefaultPassword;
    setUseDefaultPassword(newState);
    
    if (newState) {
      // ถ้าเลือกใช้รหัสผ่านเริ่มต้น ให้ล้างข้อผิดพลาดและกำหนดรหัสผ่านเริ่มต้น
      setPasswordError("");
      setConfirmPasswordError("");
      setFormData({
        ...formData,
        password: defaultPassword,
        force_password_change: true // กำหนดให้บังคับเปลี่ยนรหัสผ่านเมื่อล็อกอิน
      });
      setConfirmPassword(defaultPassword);
    } else {
      // ถ้ายกเลิกการใช้รหัสผ่านเริ่มต้น ให้กลับไปที่ค่าว่าง
      setFormData({
        ...formData,
        password: "",
        force_password_change: false
      });
      setConfirmPassword("");
    }
  };

  // ตรวจสอบก่อนส่งฟอร์ม
  const onSubmit = (e) => {
    e.preventDefault();
    
    const isPasswordValid = validatePassword(formData.password);
    const isConfirmPasswordValid = validateConfirmPassword();
    
    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }
    
    handleSubmit(e);
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <Card className="w-full max-w-md p-7">
          <div className='justify-end flex'>
            <button
              onClick={() => setIsModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          <div className="flex justify-center items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              {editingUser ? 'แก้ไขผู้ใช้' : 'เพิ่มผู้ใช้'}
            </h2>
          </div>

          <form onSubmit={onSubmit} className="space-y-4">
            <div>
              <label className="block text-base font-medium text-gray-700">รหัสสมาชิก</label>
              <input
                type="text"
                value={formData.member_id}
                placeholder="รหัสสมาชิก"
                pattern="\d*"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    member_id: e.target.value.replace(/\D/g, '')
                  })
                }
                required
                disabled={editingUser}
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {editingUser && (
              <div>
                <label className="block text-base font-medium text-gray-700">รหัสสมาชิกใหม่</label>
                <input
                  type="text"
                  placeholder="รหัสสมาชิกใหม่ (ถ้าต้องการเปลี่ยน)"
                  pattern="\d*"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      new_member_id: e.target.value.replace(/\D/g, '')
                    })
                  }
                  className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                />
                <p className="mt-1 text-sm text-red-500">
                  คำเตือน: การเปลี่ยนรหัสสมาชิกอาจส่งผลต่อข้อมูลที่เชื่อมโยงกัน
                </p>
              </div>
            )}

            {/* เพิ่มตัวเลือกใช้รหัสผ่านเริ่มต้น - แสดงเฉพาะเมื่อเพิ่มผู้ใช้ใหม่ */}
            {!editingUser && (
              <div className="mb-4">
                <div className="flex items-start">
                  <div className="flex items-center h-5 mt-0.5">
                    <input
                      type="checkbox"
                      id="default-password"
                      checked={useDefaultPassword}
                      onChange={handleDefaultPasswordChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                  </div>
                  <label htmlFor="default-password" className="ml-2 block text-sm text-gray-700">
                    ใช้รหัสผ่านเริ่มต้น ({defaultPassword}) และบังคับให้เปลี่ยนรหัสผ่านเมื่อเข้าสู่ระบบครั้งแรก
                  </label>
                </div>
                
                {useDefaultPassword && (
                  <div className="mt-2 ml-6 p-3 bg-yellow-50 border border-yellow-200 rounded-lg">
                    <div className="flex items-start">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-600 mr-2 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                      <div className="text-sm text-yellow-700">
                        <p className="font-medium mb-1">ข้อควรระวัง:</p>
                        <ul className="list-disc ml-4 space-y-1">
                          <li>รหัสผ่านเริ่มต้นนี้จะอยู่ได้<span className="font-semibold">ไม่จำกัดเวลา</span>จนกว่าผู้ใช้จะล็อกอินครั้งแรก</li>
                          <li>กรุณาแจ้งให้ผู้ใช้เข้าสู่ระบบและเปลี่ยนรหัสผ่านโดยเร็วที่สุดเพื่อความปลอดภัย</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* ส่วนกรอกรหัสผ่าน - ปรับให้ไม่สามารถแก้ไขได้เมื่อเลือกใช้รหัสผ่านเริ่มต้น */}
            <div>
              <label className="block text-base font-medium text-gray-700">รหัสผ่าน</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  required={!editingUser}
                  className={`mt-1 block w-full border ${passwordError ? "border-red-500" : "border-gray-300"} 
                    rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500
                    ${useDefaultPassword ? "bg-gray-100" : ""}`}
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    validatePassword(e.target.value);
                  }}
                  placeholder={editingUser ? "รหัสผ่านใหม่ (เว้นว่างถ้าไม่ต้องการเปลี่ยน)" : "รหัสผ่าน"}
                  disabled={useDefaultPassword} // ไม่ให้แก้ไขถ้าใช้รหัสผ่านเริ่มต้น
                />
                <button
                  type="button"
                  className={`absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ${useDefaultPassword ? "hidden" : ""}`}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
              {passwordError && <p className="mt-1 text-sm text-red-500">{passwordError}</p>}
              {!useDefaultPassword && (
                <p className="mt-1 text-xs text-gray-500">
                  รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว
                </p>
              )}
            </div>

            {/* ส่วนยืนยันรหัสผ่าน - ปรับให้ไม่สามารถแก้ไขได้เมื่อเลือกใช้รหัสผ่านเริ่มต้น */}
            <div className={useDefaultPassword ? "hidden" : ""}>
              <label className="block text-base font-medium text-gray-700">ยืนยันรหัสผ่าน</label>
              <input
                type={showPassword ? "text" : "password"}
                required={!editingUser && Boolean(formData.password) && !useDefaultPassword}
                className={`mt-1 block w-full border ${confirmPasswordError ? "border-red-500" : "border-gray-300"} rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500`}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (formData.password === e.target.value) {
                    setConfirmPasswordError("");
                  }
                }}
                placeholder="ยืนยันรหัสผ่าน"
              />
              {confirmPasswordError && <p className="mt-1 text-sm text-red-500">{confirmPasswordError}</p>}
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700">ชื่อ</label>
              <input
                type="text"
                required
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder={editingUser ? (formData.name || "ชื่อผู้ใช้งาน") : "ชื่อผู้ใช้งาน"}
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700">ระดับสิทธิ์</label>
              <select
                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                value={formData.access_level}
                onChange={(e) => setFormData({ ...formData, access_level: e.target.value })}
                required
              >
                <option value="">-- เลือกระดับสิทธิ์ ---</option>
                <option value="acc">Accounting</option>
                <option value="fin">Finance</option>
                <option value="wh">Warehouse</option>
                <option value="hr">Human Resources</option>
                <option value="it">Information Technology</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                <Save className="h-5 w-5" />
                บันทึก
              </button>
            </div>
          </form>
        </Card>
      </div>
    )
  );
};

export default UserModal;