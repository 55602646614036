import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Eye, EyeOff, Lock, Shield, AlertCircle, Check } from 'lucide-react';
import axios from '../../utils/axiosConfig';
import { Toast } from '../../lib/sweetAlertConfig'; // เพิ่มการนำเข้า Toast

const ForceChangePassword = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [memberInfo, setMemberInfo] = useState(null);
  const navigate = useNavigate();

  // ตรวจสอบ token และ redirect ถ้าผู้ใช้ไม่ต้องเปลี่ยนรหัสผ่าน
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const decoded = jwtDecode(token);
      if (!decoded.force_password_change) {
        navigate('/home');
        return;
      }
      
      // เก็บข้อมูลผู้ใช้เพื่อแสดงในหน้า
      setMemberInfo({
        member_id: decoded.member_id,
        name: decoded.name
      });
    } catch (e) {
      console.error('Token decode error:', e);
      localStorage.removeItem('token');
      navigate('/login');
    }
  }, [navigate]);

  // ตรวจสอบความแข็งแรงของรหัสผ่าน
  const checkPasswordStrength = (password) => {
    let score = 0;
    
    // ความยาวมากกว่า 8 ตัว
    if (password.length > 8) score += 25;
    
    // มีตัวเลข
    if (/\d/.test(password)) score += 25;
    
    // มีตัวพิมพ์เล็ก และพิมพ์ใหญ่
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score += 25;
    
    // มีอักขระพิเศษ
    if (/[^A-Za-z0-9]/.test(password)) score += 25;
    
    setPasswordStrength(score);
    
    return score;
  };

  // ตรวจสอบความถูกต้องของรหัสผ่าน
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/;
    
    if (!passwordRegex.test(password)) {
      setPasswordError("รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว");
      return false;
    }
    
    setPasswordError("");
    return true;
  };

  // การเปลี่ยนค่า input field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  // การส่งฟอร์มเปลี่ยนรหัสผ่าน
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (formData.password !== formData.confirmPassword) {
      setError('รหัสผ่านและรหัสผ่านยืนยันไม่ตรงกัน');
      return;
    }
    
    if (!validatePassword(formData.password)) {
      return;
    }
    
    setLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      const decoded = jwtDecode(token);
      
      await axios.post('/change-password', {
        member_id: decoded.member_id,
        new_password: formData.password
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      localStorage.removeItem('token');
      
      // เปลี่ยนจาก MySwal.fire เป็น Toast.fire
      Toast.fire({
        icon: 'success',
        title: 'เปลี่ยนรหัสผ่านสำเร็จ',
        text: 'กรุณาเข้าสู่ระบบอีกครั้งด้วยรหัสผ่านใหม่'
      });
      
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error('Error changing password:', error);
      
      // เปลี่ยนจาก MySwal.fire เป็น Toast.fire
      Toast.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.response?.data?.message || 'เกิดข้อผิดพลาดในการเปลี่ยนรหัสผ่าน'
      });
      
      setError(error.response?.data?.message || 'เกิดข้อผิดพลาดในการเปลี่ยนรหัสผ่าน');
    } finally {
      setLoading(false);
    }
  };

  // ถ้ายังไม่มีข้อมูลผู้ใช้ ให้แสดง skeleton loading
  if (!memberInfo) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <div className="w-full max-w-md p-6 bg-white rounded-xl shadow-lg animate-pulse">
          <div className="flex justify-center mb-6">
            <div className="rounded-full bg-gray-300 h-12 w-12"></div>
          </div>
          <div className="h-6 bg-gray-300 rounded mb-6 w-3/4 mx-auto"></div>
          <div className="h-4 bg-gray-300 rounded mb-8 w-5/6 mx-auto"></div>
          <div className="space-y-4">
            <div className="h-10 bg-gray-300 rounded"></div>
            <div className="h-10 bg-gray-300 rounded"></div>
            <div className="h-10 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-md p-6 bg-white rounded-xl shadow-lg">
        {/* ส่วนหัวของหน้า */}
        <div className="flex flex-col items-center mb-6">
          <div className="p-3 rounded-full bg-blue-100 mb-4">
            <Shield className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-2xl font-bold text-center">เปลี่ยนรหัสผ่านใหม่</h2>
          <p className="text-center text-gray-600 mt-2">
            กรุณาตั้งรหัสผ่านใหม่เพื่อความปลอดภัย
          </p>
        </div>
        
        {/* ส่วนข้อความแจ้งเตือน */}
        <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg">
          <div className="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p className="ml-3 text-sm text-blue-700">
              คุณกำลังใช้รหัสผ่านชั่วคราว กรุณาตั้งรหัสผ่านใหม่ที่จำได้ง่ายแต่ปลอดภัยสำหรับการใช้งานในอนาคต
            </p>
          </div>
        </div>
        
        {/* ส่วนข้อมูลผู้ใช้ */}
        <div className="mb-6 p-4 bg-blue-50 rounded-lg">
          <div className="flex items-center">
            <div className="mr-3 rounded-full bg-blue-500 p-1 flex-shrink-0">
              <Check className="h-4 w-4 text-white" />
            </div>
            <div>
              <div className="font-medium">{memberInfo.name}</div>
              <div className="text-blue-600 text-sm">รหัสสมาชิก: {memberInfo.member_id}</div>
            </div>
          </div>
        </div>
        
        {/* ข้อความแจ้งเตือนข้อผิดพลาด */}
        {error && (
          <div className="mb-6 p-3 bg-red-50 text-red-700 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        )}
        
        {/* ฟอร์มเปลี่ยนรหัสผ่าน */}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* รหัสผ่านใหม่ */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              รหัสผ่านใหม่
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                className={`pl-10 pr-10 py-2 w-full border ${passwordError ? "border-red-500" : "border-gray-300"} rounded-md focus:ring-blue-500 focus:border-blue-500`}
                value={formData.password}
                name="password"
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
              </button>
            </div>
            
            {/* ตัวบอกความแข็งแรงของรหัสผ่าน */}
            <div className="mt-2">
              <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className={`h-full ${
                    passwordStrength < 50 ? 'bg-red-500' : 
                    passwordStrength < 75 ? 'bg-yellow-500' : 
                    'bg-green-500'
                  }`} 
                  style={{ width: `${passwordStrength}%` }}
                ></div>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                {passwordStrength < 50 ? 'รหัสผ่านไม่ปลอดภัย' : 
                 passwordStrength < 75 ? 'รหัสผ่านปลอดภัยปานกลาง' : 
                 'รหัสผ่านปลอดภัย'}
              </p>
            </div>
            
            {/* ข้อความแจ้งเตือนรหัสผ่านไม่ถูกต้อง */}
            {passwordError && (
              <p className="mt-1 text-sm text-red-500">{passwordError}</p>
            )}
            
            {/* คำแนะนำรหัสผ่าน */}
            <p className="mt-2 text-xs text-gray-500">
              รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ และอักขระพิเศษ รวมกันอย่างน้อย 10 ตัว
            </p>
          </div>
          
          {/* ยืนยันรหัสผ่านใหม่ */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              ยืนยันรหัสผ่านใหม่
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                className={`pl-10 py-2 w-full border ${formData.password !== formData.confirmPassword && formData.confirmPassword ? "border-red-500" : "border-gray-300"} rounded-md focus:ring-blue-500 focus:border-blue-500`}
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                required
              />
            </div>
            
            {/* ข้อความแจ้งเตือนรหัสผ่านไม่ตรงกัน */}
            {formData.password !== formData.confirmPassword && formData.confirmPassword && (
              <div className="flex items-center mt-2">
                <AlertCircle className="h-4 w-4 text-red-500 mr-1" />
                <p className="text-sm text-red-500">รหัสผ่านไม่ตรงกัน</p>
              </div>
            )}
          </div>
          
          {/* ปุ่มเปลี่ยนรหัสผ่าน */}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 
                    focus:ring-4 focus:ring-blue-300 transition-all duration-300 text-sm font-medium
                    flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed
                    hover:shadow-md mt-2"
          >
            {loading ? (
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : 'เปลี่ยนรหัสผ่าน'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForceChangePassword;