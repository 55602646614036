import axios from 'axios';
import { API_URL } from './configBridge';

// เปลี่ยนจาก hardcoded URL เป็น URL จาก config
const apiBaseUrl = API_URL;

// Class สำหรับจัดการ Activity Logging
class ActivityLogger {
  constructor() {
    this.enabled = true;
    this.apiBaseUrl = apiBaseUrl;
    this.sessionStartTime = Date.now();
    this.pageLoadTime = Date.now();
    this.currentPage = window.location.pathname;
    this.lastActiveTime = Date.now();
    this.inactivityTimer = null;
    this.hasWarnedNoToken = false; // เพิ่มตัวแปรเพื่อควบคุมการแสดงข้อความเตือน
    this.loggingFrequency = {
      page_view: 1,     // บันทึกทุกครั้ง
      page_exit: 0.5    // บันทึก 50% ของเหตุการณ์
    };
    this.samplingBuffer = {};
    this.setupInactivityTracking();
    this.eventQueue = [];
    this.processingTimer = null;
    this.queueThreshold = 10; // จำนวนเหตุการณ์ที่จะรวมกันก่อนส่ง
    this.maxQueueTime = 30000; // ส่งทุก 30 วินาทีไม่ว่าจะมีกี่เหตุการณ์
  }

  // ตั้งค่าการติดตามการไม่ใช้งานระบบ
  setupInactivityTracking() {
    // ติดตามเมื่อมีการโต้ตอบกับหน้าเว็บ
    const resetInactivityTimer = () => {
      this.lastActiveTime = Date.now();
      clearTimeout(this.inactivityTimer);
      
      // ตั้ง timer ใหม่: ถ้าไม่มีการโต้ตอบเป็นเวลา 15 นาที ถือว่าไม่ได้ใช้งาน
      this.inactivityTimer = setTimeout(() => {
        this.logActivity({
          event_type: 'inactivity',
          additional_info: {
            duration: Math.floor((Date.now() - this.lastActiveTime) / 1000),
            page: window.location.pathname
          }
        });
      }, 15 * 60 * 1000); // 15 นาที
    };
    
    // เพิ่ม event listeners เพื่อติดตามการโต้ตอบกับหน้าเว็บ
    ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(event => {
      document.addEventListener(event, resetInactivityTimer, { passive: true });
    });

    // ติดตามเมื่อปิดแท็บหรือปิดเบราว์เซอร์
    window.addEventListener('beforeunload', () => {
      this.logActivity({
        event_type: 'app_exit',
        additional_info: {
          page: window.location.pathname,
          session_duration: Math.floor((Date.now() - this.sessionStartTime) / 1000),
          page_duration: Math.floor((Date.now() - this.pageLoadTime) / 1000)
        }
      });
    });
    
    // เริ่มต้นติดตามการไม่ใช้งาน
    resetInactivityTimer();
  }

  // บันทึกเมื่อเปิดหน้าใหม่
  pageView() {
    // ถ้าไม่ใช่หน้าแรกที่เข้าชม ให้บันทึกการออกจากหน้าก่อนหน้า
    if (this.currentPage && this.currentPage !== window.location.pathname) {
      this.pageExit();
    }
    
    this.pageLoadTime = Date.now();
    this.currentPage = window.location.pathname;
    this.logActivity({
      event_type: 'page_view',
      additional_info: {
        page: this.currentPage,
        referrer: document.referrer || 'direct',
        window_size: `${window.innerWidth}x${window.innerHeight}`
      }
    });
  }
  
  // บันทึกเมื่อออกจากหน้าเว็บ (เปลี่ยนไปหน้าอื่น)
  pageExit() {
    const pageDuration = Math.floor((Date.now() - this.pageLoadTime) / 1000); // เวลาที่อยู่ในหน้านี้เป็นวินาที
    this.logActivity({
      event_type: 'page_exit',
      additional_info: {
        page: this.currentPage,
        duration: pageDuration,
        scroll_depth: this.getScrollDepth()
      }
    });
  }
  
  // คำนวณความลึกของการเลื่อนหน้า (scroll depth)
  getScrollDepth() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const docHeight = Math.max(
      document.body.scrollHeight, 
      document.body.offsetHeight, 
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight, 
      document.documentElement.offsetHeight
    );
    const windowHeight = window.innerHeight;
    const scrollPercent = Math.round((scrollTop / (docHeight - windowHeight)) * 100);
    return Math.min(scrollPercent, 100); // ไม่เกิน 100%
  }

  // บันทึกเมื่อล็อกอิน
  login(username, isAdmin = false) {
    this.sessionStartTime = Date.now();
    this.logActivity({
      event_type: 'login',
      additional_info: {
        username,
        user_type: isAdmin ? 'admin' : 'user'
      }
    });
  }

  // แก้ไขฟังก์ชัน logout เพื่อระบุสถานะให้ชัดเจน
  logout() {
    try {
      // คำนวณระยะเวลาเซสชัน
      const sessionDuration = Math.floor((Date.now() - this.sessionStartTime) / 1000);
      
      // ตรวจสอบว่าเป็น admin หรือ user
      const isAdmin = !!localStorage.getItem('admin_token');
      const tokenKey = isAdmin ? 'admin_token' : 'token';
      const token = localStorage.getItem(tokenKey);
      
      if (!token) {
        console.log("ไม่สามารถบันทึกกิจกรรมออกจากระบบ: ไม่พบ token");
        return;
      }
      
      // สร้าง payload โดยระบุสถานะ "success" อย่างชัดเจน
      const payload = {
        event_type: 'logout',
        page_url: window.location.pathname,
        event_timestamp: new Date().toISOString(),
        status: 'success', // เพิ่มบรรทัดนี้
        additional_info: {
          user_type: isAdmin ? 'admin' : 'user',
          duration: sessionDuration
        }
      };

      // ใช้ Navigator.sendBeacon ถ้ามี
      if (navigator.sendBeacon) {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        
        navigator.sendBeacon(
          `${this.apiBaseUrl}/activity/log`, 
          new Blob([JSON.stringify(payload)], { type: 'application/json' })
        );
      } else {
        // ใช้ fetch กับ keepalive: true
        fetch(`${this.apiBaseUrl}/activity/log`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
          keepalive: true
        }).catch(e => console.error("Error sending logout event:", e));
      }
    } catch (error) {
      console.error("Error in logout activity logging:", error);
    }
  }

  // เพิ่มฟังก์ชันตรวจสอบการสุ่มตัวอย่าง
  shouldLogEvent(eventType) {
    // เหตุการณ์สำคัญบันทึกเสมอ
    const criticalEvents = ['login', 'logout', 'file_upload', 'error'];
    if (criticalEvents.includes(eventType)) return true;
    
    // สุ่มตัวอย่างตามความถี่ที่กำหนด
    const frequency = this.loggingFrequency[eventType] || 1;
    return Math.random() <= frequency;
  }

  // ฟังก์ชันสำหรับบันทึก activity
  logActivity(eventData) {
    // เฉพาะเหตุการณ์สำคัญให้ส่งทันที
    if (['login', 'logout', 'error'].includes(eventData.event_type)) {
      this._sendImmediately(eventData);
      return;
    }
    
    // เพิ่มลงในคิว
    this.eventQueue.push({
      ...eventData,
      timestamp: Date.now()
    });
    
    // ตั้งเวลาส่งคิวหากยังไม่ได้ตั้ง
    if (!this.processingTimer) {
      this.processingTimer = setTimeout(() => this.processQueue(), this.maxQueueTime);
    }
    
    // ส่งทันทีถ้าจำนวนในคิวถึงเกณฑ์
    if (this.eventQueue.length >= this.queueThreshold) {
      this.processQueue();
    }
  }

  processQueue() {
    if (this.eventQueue.length === 0) return;
    
    // ล้าง timer ถ้ามี
    if (this.processingTimer) {
      clearTimeout(this.processingTimer);
      this.processingTimer = null;
    }
    
    // คัดลอกเหตุการณ์ในคิวและเคลียร์คิว
    const events = [...this.eventQueue];
    this.eventQueue = [];
    
    // ส่งเหตุการณ์ทั้งหมดพร้อมกัน
    this._sendBatchEvents(events);
  }

  _sendBatchEvents(events) {
    try {
      const token = localStorage.getItem('token') || localStorage.getItem('admin_token');
      if (!token) return;
      
      fetch(`${this.apiBaseUrl}/activity/batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ events }),
        keepalive: true
      }).catch(e => console.error("Error sending batch events:", e));
    } catch (error) {
      console.error("Error in batch processing:", error);
    }
  }

  _sendImmediately(eventData) {
    const { event_type } = eventData;

    // ตรวจสอบว่าควรบันทึกเหตุการณ์นี้หรือไม่
    if (!this.shouldLogEvent(event_type)) {
      return;
    }

    // ตรวจสอบว่าเป็นหน้า login หรือไม่
    const isLoginPage = window.location.pathname === '/' || 
                        window.location.pathname === '/login' || 
                        window.location.pathname === '/adminlogin';
    
    try {
      // ตรวจสอบว่าเป็น admin หรือ user
      const isAdmin = !!localStorage.getItem('admin_token');
      const tokenKey = isAdmin ? 'admin_token' : 'token';
      const token = localStorage.getItem(tokenKey);
      
      if (!token) {
        // แสดงข้อความแจ้งเตือนเฉพาะครั้งแรกและไม่ใช่หน้า login
        if (!this.hasWarnedNoToken && !isLoginPage) {
          console.log("ไม่สามารถบันทึกกิจกรรมได้: ไม่พบ token");
          this.hasWarnedNoToken = true;
        }
        return;
      }
      
      // รีเซ็ตสถานะการแสดงข้อความเตือนเมื่อมี token แล้ว
      this.hasWarnedNoToken = false;

      // เพิ่มข้อมูลประเภทผู้ใช้ในกรณีที่ไม่มีข้อมูลนี้ในอีเวนต์
      const { event_type, action_details, status = 'success' } = eventData;
      const additional_info = eventData.additional_info || {};
      
      if (!additional_info.user_type) {
        additional_info.user_type = isAdmin ? 'admin' : 'user';
      }

      // ลบ timezone และ timezone_offset
      if (additional_info.timezone) delete additional_info.timezone;
      if (additional_info.timezone_offset) delete additional_info.timezone_offset;

      // สร้าง payload
      const payload = {
        event_type,
        page_url: window.location.pathname,
        action_details: action_details || {},
        status,
        additional_info,
        event_timestamp: new Date().toISOString() // ไม่มีการแปลงเขตเวลาใดๆ
      };

      // เลือก endpoint ตามประเภทผู้ใช้
      const endpoint = isAdmin ? '/activity/admin/log' : '/activity/log';

      // ส่งคำขอไปยัง API พร้อม token ที่ถูกต้อง
      console.log('Logging activity:', event_type, 'with token prefix:', token.substring(0, 15) + '...');
      
      axios.post(endpoint, payload, {
        headers: { 
          Authorization: `Bearer ${token}`
        }
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          console.log("Activity logging: Authentication token expired or invalid");
        } else {
          console.error("Error logging activity:", error);
        }
      });
    } catch (error) {
      console.error("Error logging activity:", error);
    }
  }
  
  // ฟังก์ชันรับข้อมูลเบราว์เซอร์
  getBrowserInfo() {
    const ua = navigator.userAgent;
    let browserName = "Unknown";
    
    if (ua.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (ua.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (ua.match(/safari/i)) {
      browserName = "Safari";
    } else if (ua.match(/opr\//i)) {
      browserName = "Opera";
    } else if (ua.match(/edg/i)) {
      browserName = "Edge";
    } else if (ua.match(/msie|trident/i)) {
      browserName = "Internet Explorer";
    }
    
    return browserName;
  }
  
  // ฟังก์ชันรับข้อมูลระบบปฏิบัติการ
  getOSInfo() {
    const ua = navigator.userAgent;
    let os = "Unknown";
    
    if (ua.match(/windows nt/i)) {
      os = "Windows";
    } else if (ua.match(/macintosh|mac os x/i)) {
      os = "MacOS";
    } else if (ua.match(/linux/i)) {
      os = "Linux";
    } else if (ua.match(/android/i)) {
      os = "Android";
    } else if (ua.match(/iphone|ipad|ipod/i)) {
      os = "iOS";
    }
    
    return os;
  }

  // เพิ่มเมธอดสำหรับฟอร์แมตเวลาให้เป็น ISO string
  formatTimeWithTimezone(timestamp) {
    // ใช้ ISO format โดยตรง ไม่มีการปรับแต่งใดๆ เพื่อป้องกันการแปลง timezone
    return new Date(timestamp).toISOString();
  }
}

// เพิ่มฟังก์ชัน logActivity ใหม่
const logActivity = async (action, details = {}) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      // ไม่ต้องแสดงข้อความเตือนที่นี่ ให้จัดการเฉพาะในคลาส ActivityLogger
      return;
    }
    
    // เรียกใช้ logger.logActivity
    logger.logActivity({
      event_type: action,
      action_details: details
    });
  } catch (error) {
    console.error("เกิดข้อผิดพลาดในการบันทึกกิจกรรม:", error);
  }
};

// สร้าง singleton instance
const logger = new ActivityLogger();

// ส่งออกทั้ง logger และ logActivity เป็น function แยก
export { logActivity };
export default logger;