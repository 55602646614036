import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { 
  Plus, 
  Search, 
  Edit2, 
  Trash2, 
  ArrowLeft, 
  FileText,
  Calendar,
  User,
  Image as ImageIcon,
  Eye,
  MoveVertical,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Type,
  Clock
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm'; 
import { markdownComponents, getButtonGroups } from '../../lib/markdownUtils';
import { Toast } from '../../lib/sweetAlertConfig';

const MySwal = withReactContent(Swal);

// เพิ่ม Skeleton Components

// Table Row Skeleton สำหรับตารางปกติ
const TableRowSkeleton = () => (
  <tr className="animate-pulse">
    <td className="px-3 py-4 whitespace-nowrap">
      <div className="flex justify-center cursor-move">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10 mr-4 relative bg-gray-200 rounded"></div>
        <div className="ml-0">
          <div className="h-4 w-40 bg-gray-200 rounded mb-2"></div>
          <div className="h-3 w-24 bg-gray-200 rounded"></div>
        </div>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
          <div className="h-4 w-20 bg-gray-200 rounded"></div>
        </div>
        <div className="flex items-center mt-1">
          <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
          <div className="h-3 w-24 bg-gray-200 rounded"></div>
        </div>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-4 w-16 bg-gray-200 rounded"></div>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex justify-start">
        <div className="h-5 w-16 bg-gray-200 rounded-full"></div>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex justify-start">
        <div className="h-5 w-16 bg-gray-200 rounded-full"></div>
      </div>
    </td>
    <td className="px-6 py-4 text-center">
      <div className="flex justify-center space-x-2">
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      </div>
    </td>
  </tr>
);

// Mobile Card Skeleton
const MobileCardSkeleton = () => (
  <div className="border rounded-lg overflow-hidden animate-pulse mb-3">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <div className="h-4 w-16 bg-gray-200 rounded-full"></div>
        <div className="h-4 w-16 bg-gray-200 rounded-full"></div>
      </div>
      <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
    </div>
    
    <div className="p-3">
      <div className="flex items-start mb-2">
        <div className="w-12 h-12 bg-gray-200 rounded flex-shrink-0 mr-3"></div>
        <div>
          <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
          <div className="h-3 w-full bg-gray-200 rounded"></div>
        </div>
      </div>
      
      <div className="flex flex-wrap gap-y-1.5 mt-3">
        <div className="h-3 w-16 bg-gray-200 rounded mr-4"></div>
        <div className="h-3 w-16 bg-gray-200 rounded"></div>
        <div className="h-3 w-24 bg-gray-200 rounded ml-auto"></div>
      </div>
    </div>
    
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-6 w-10 bg-gray-200 rounded"></div>
      <div className="h-6 w-14 bg-gray-200 rounded"></div>
      <div className="h-6 w-10 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const KnowledgeManagement = () => {
  // State สำหรับเก็บข้อมูลต่างๆ
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    summary: '', // เพิ่มฟิลด์ summary สำหรับเนื้อหาโดยย่อ
    date_posted: new Date(),
    author: '',
    category: 'general',
    image_url: '',
    is_active: true
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const navigate = useNavigate();

  // เพิ่มในส่วนด้านบน Component ก่อนส่วน return
  const buttonGroups = getButtonGroups();

  // เพิ่มฟังก์ชัน insertMarkdown จาก AnnounManagement.js
  const insertMarkdown = (example) => {
    const textArea = document.getElementById('article-content');
    if (!textArea) return;
    
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const text = formData.content;
    const beforeText = text.substring(0, start);
    const selectedText = text.substring(start, end);
    const afterText = text.substring(end);
    
    let newText = '';
    let placeCursor = 0;
    
    // กรณีเป็นปุ่มจัดเรียง
    if (example.prefix.includes('align')) {
      if (selectedText) {
        newText = beforeText + example.prefix + selectedText + example.suffix + afterText;
        placeCursor = beforeText.length + example.prefix.length + selectedText.length; // ตำแหน่งหลังข้อความที่เลือก
      } else {
        newText = beforeText + example.prefix + "ข้อความที่ต้องการจัดเรียง" + example.suffix + afterText;
        placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
      }
    }
    // กรณีเป็นปุ่มลิงก์
    else if (example.middle) {
      if (selectedText) {
        newText = beforeText + example.prefix + selectedText + example.middle + "URL" + example.suffix + afterText;
        placeCursor = beforeText.length + example.prefix.length + selectedText.length + example.middle.length;
      } else {
        newText = beforeText + example.prefix + "ข้อความ" + example.middle + "URL" + example.suffix + afterText;
        placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
      }
    } 
    // กรณีเป็นปุ่มอื่นๆ 
    else {
      if (selectedText) {
        newText = beforeText + example.prefix + selectedText + (example.suffix || '') + afterText;
        placeCursor = beforeText.length + example.prefix.length + selectedText.length; // ตำแหน่งหลังข้อความที่เลือก
      } else {
        newText = beforeText + example.prefix + "ข้อความ" + (example.suffix || '') + afterText;
        placeCursor = beforeText.length + example.prefix.length; // ตำแหน่งเริ่มต้นของ placeholder
      }
    }
    
    setFormData({ ...formData, content: newText });
    
    // โฟกัสที่ textarea และตั้งค่าตำแหน่งเคอร์เซอร์หลังจากแทรก
    setTimeout(() => {
      textArea.focus();
      
      if (selectedText) {
        // ถ้ามีข้อความที่เลือก ให้วางเคอร์เซอร์ไว้หลังข้อความที่เลือก
        textArea.setSelectionRange(placeCursor, placeCursor);
      } else {
        // ถ้าไม่มีข้อความที่เลือก ให้เลือกข้อความ placeholder
        const startPos = beforeText.length + example.prefix.length;
        const endPos = startPos + "ข้อความ".length;
        textArea.setSelectionRange(startPos, endPos);
      }
    }, 0);
  };

  // ดึงข้อมูลบทความจาก API
  const fetchArticles = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('admin_token');
      
      if (!token) {
        throw new Error('ไม่พบข้อมูล Admin Token');
      }
      
      const headers = { Authorization: `Bearer ${token}` };
      
      const response = await axios.get('/admin/knowledge', { headers });
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching knowledge articles:', error);
      
      if (error.response?.status === 401 || error.response?.status === 403) {
        MySwal.fire({
          title: 'กรุณาเข้าสู่ระบบ',
          text: 'คุณไม่ได้เข้าสู่ระบบหรือ session หมดอายุ',
          icon: 'warning',
          confirmButtonText: 'เข้าสู่ระบบ',
          confirmButtonColor: '#3085d6'
        }).then(() => {
          navigate('/adminlogin');
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถโหลดข้อมูลบทความได้',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } finally {
      // เพิ่ม timeout เพื่อให้ skeleton แสดงได้นานพอ
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [navigate]);

  // โหลดข้อมูลเมื่อเริ่มต้น
  useEffect(() => {
    const adminToken = localStorage.getItem('admin_token');
    if (!adminToken) {
      MySwal.fire({
        title: 'ไม่มีสิทธิ์เข้าถึง!',
        text: 'กรุณาเข้าสู่ระบบผู้ดูแลระบบก่อนใช้งานส่วนนี้',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      }).then(() => {
        navigate('/adminlogin');
      });
      return;
    }
    
    fetchArticles();
  }, [fetchArticles, navigate]);
  
  // กรองบทความตาม searchTerm
  const filteredArticles = articles.filter((article) => {
    const titleMatch = article.title.toLowerCase().includes(searchTerm.toLowerCase());
    const contentMatch = article.content.toLowerCase().includes(searchTerm.toLowerCase());
    const authorMatch = article.author && article.author.toLowerCase().includes(searchTerm.toLowerCase());
    
    return titleMatch || contentMatch || authorMatch;
  });

  // สำหรับการรีเซ็ตฟอร์ม
  const resetForm = () => {
    setEditingArticle(null);
    setFormData({
      title: '',
      content: '',
      summary: '', // เพิ่มฟิลด์ summary
      date_posted: new Date(),
      author: '',
      category: 'general',
      image_url: '',
      is_active: true
    });
    setImagePreview(null);
    setPreviewFile(null);
  };

  // ฟังก์ชันจัดการเมื่อมีการเปลี่ยนแปลงข้อมูลในฟอร์ม
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // ฟังก์ชันจัดการเมื่อมีการเปลี่ยนแปลงรูปภาพ
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewFile(file);
      
      // สร้าง URL สำหรับแสดงตัวอย่างภาพ
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // ฟังก์ชันจัดการเมื่อกดปุ่มแก้ไขบทความ
  const handleEdit = (article) => {
    setEditingArticle(article);
    setFormData({
      title: article.title || '',
      content: article.content || '',
      summary: article.summary || '', // เพิ่มฟิลด์ summary
      date_posted: article.date_posted ? new Date(article.date_posted) : new Date(),
      author: article.author || '',
      category: article.category || 'general',
      image_url: article.image_filename || '', // ปรับจาก image_url เป็น image_filename
      is_active: article.is_active !== undefined ? article.is_active : true
    });
    
    // ถ้ามีรูปภาพ ให้ดึงมาแสดงตัวอย่าง
    if (article.image_filename) {
      setImagePreview(`${process.env.REACT_APP_UPLOAD_URL}/article/${article.image_filename}`);
    } else {
      setImagePreview(null);
    }
    
    setIsFormVisible(true);
  };

  // ปรับปรุงฟังก์ชัน handleSubmit
const handleSubmit = async (e) => {
  e.preventDefault();
  
  // ถ้ากำลังโหลดอยู่แล้ว ไม่ต้องทำอะไร
  if (loading) return;
  
  setLoading(true);
  
  try {
    if (!formData.title.trim() || !formData.content.trim()) {
      MySwal.fire({
        title: 'ข้อมูลไม่ครบถ้วน!',
        text: 'กรุณากรอกชื่อและเนื้อหาบทความ',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
      setLoading(false);
      return;
    }

    // ถ้าไม่ได้กรอกเนื้อหาโดยย่อ ให้ใช้เนื้อหาหลัก 200 ตัวอักษรแรก
    const summaryText = formData.summary.trim() || 
      (formData.content.length > 200 
        ? formData.content.substring(0, 197) + '...' 
        : formData.content);

    const token = localStorage.getItem('admin_token');
    if (!token) {
      throw new Error('ไม่พบข้อมูล Admin Token');
    }

    // สร้าง FormData สำหรับการอัปโหลดไฟล์
    const articleData = new FormData();
    articleData.append('title', formData.title);
    articleData.append('content', formData.content);
    articleData.append('content_format', 'markdown'); // เพิ่มฟิลด์นี้เพื่อระบุว่าเป็นเนื้อหาแบบ markdown
    articleData.append('summary', summaryText);
    articleData.append('date_posted', format(new Date(formData.date_posted), 'yyyy-MM-dd'));
    articleData.append('author', formData.author || 'Admin');
    articleData.append('category', formData.category);
    articleData.append('is_active', formData.is_active ? '1' : '0');

    console.log("Submitting form data:", {
      title: formData.title,
      content: formData.content.substring(0, 50) + "...", // log เฉพาะส่วนแรกของเนื้อหา
      date_posted: format(new Date(formData.date_posted), 'yyyy-MM-dd'),
      author: formData.author || 'Admin',
      category: formData.category,
      is_active: formData.is_active ? '1' : '0'
    });

    // ถ้ามีการอัปโหลดไฟล์ใหม่ ให้เพิ่มไฟล์เข้าไปใน FormData
    if (previewFile) {
      console.log("Adding image to form data:", previewFile.name, previewFile.type, previewFile.size);
      articleData.append('image', previewFile);
    }

    let response;
    const headers = { 
      Authorization: `Bearer ${token}`
    };

    // ทดสอบ log
    console.log('Submitting article...', articleData);
    
    if (editingArticle) {
      console.log("Updating article ID:", editingArticle.article_id);
      response = await axios.put(
        `/admin/knowledge/${editingArticle.article_id}`,
        articleData,
        { 
          headers,
          maxContentLength: Infinity,
          maxBodyLength: Infinity 
        }
      );
    } else {
      console.log("Creating new article");
      response = await axios.post(
        '/admin/knowledge',
        articleData,
        { 
          headers,
          maxContentLength: Infinity,
          maxBodyLength: Infinity 
        }
      );
      console.log('API response:', response);
    }

    if (response.status === 200 || response.status === 201) {
      setLoading(false);
      setIsFormVisible(false);
      fetchArticles();
      
      // Use Toast instead of MySwal for success message
      Toast.fire({
        icon: 'success',
        title: editingArticle 
          ? 'อัปเดตบทความเรียบร้อยแล้ว' 
          : 'เพิ่มบทความเรียบร้อยแล้ว'
      });
      
      resetForm();
    }
  } catch (error) {
    console.error('Error submitting article:', error);
    console.error('Error details:', error.response?.data);

    if (error.response?.status === 401 || error.response?.status === 403) {
      MySwal.fire({
        title: 'กรุณาเข้าสู่ระบบใหม่',
        text: 'Session หมดอายุหรือไม่มีสิทธิ์เข้าถึงระบบนี้',
        icon: 'warning',
        confirmButtonText: 'เข้าสู่ระบบ',
        confirmButtonColor: '#3085d6'
      }).then(() => {
        navigate('/adminlogin');
      });
    } else {
      MySwal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: `ไม่สามารถบันทึกบทความได้: ${error.response?.data?.error || error.message}`,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6'
      });
    }
  } finally {
    setLoading(false);
  }
};

  // ฟังก์ชันจัดการเมื่อกดปุ่มลบบทความ
  const handleDelete = (id) => {
    MySwal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: 'คุณจะไม่สามารถย้อนกลับได้!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const token = localStorage.getItem('admin_token');
          if (!token) {
            throw new Error('ไม่พบข้อมูล Admin Token');
          }
          
          // ส่งคำสั่งลบบทความไปยัง API
          const response = await axios.delete(`/admin/knowledge/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          
          if (response.status === 200) {
            // Use Toast instead of MySwal for success message
            Toast.fire({
              icon: 'success',
              title: 'ลบบทความเรียบร้อยแล้ว'
            });
            
            // ดึงข้อมูลใหม่หลังจากลบ
            fetchArticles();
          }
        } catch (error) {
          console.error('Error deleting article:', error);
          
          if (error.response?.status === 401 || error.response?.status === 403) {
            MySwal.fire({
              title: 'กรุณาเข้าสู่ระบบ',
              text: 'คุณไม่ได้เข้าสู่ระบบหรือ session หมดอายุ',
              icon: 'warning',
              confirmButtonText: 'เข้าสู่ระบบ',
              confirmButtonColor: '#3085d6'
            }).then(() => {
              navigate('/adminlogin');
            });
          } else {
            MySwal.fire({
              title: 'เกิดข้อผิดพลาด!',
              text: `ไม่สามารถลบบทความได้: ${error.message || 'กรุณาลองใหม่อีกครั้ง'}`,
              icon: 'error',
              confirmButtonText: 'ตกลง',
              confirmButtonColor: '#3085d6'
            });
          }
        } finally {
          setLoading(false);
        }
      }
    });
  };

  // ฟังก์ชันจัดการการลากและวาง
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(articles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    // อัปเดตลำดับ order_position
    const updatedItems = items.map((item, index) => ({
      ...item,
      order_position: items.length - index // รายการแรก = ค่าสูงสุด
    }));
    
    setArticles(updatedItems);
    setIsOrderChanged(true);
  };
  
  // ฟังก์ชันบันทึกลำดับ
  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('admin_token');
      
      if (!token) {
        throw new Error('ไม่พบข้อมูล Admin Token');
      }
      
      // สร้าง API endpoint ใหม่สำหรับการจัดลำดับ
      const orderData = articles.map(item => ({
        id: item.article_id,
        order_position: item.order_position
      }));
      
      console.log('Token in handleSaveOrder:', token); // เพิ่ม log เพื่อตรวจสอบ
      console.log('Order data:', orderData); // เพิ่ม log เพื่อตรวจสอบ
      
      const response = await axios.post(
        '/admin/knowledge/reorder',
        { articles: orderData },
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          } 
        }
      );
      
      if (response.status === 200) {
        setIsOrderChanged(false);
        
        // Use Toast instead of MySwal for success message
        Toast.fire({
          icon: 'success',
          title: 'บันทึกลำดับบทความเรียบร้อยแล้ว'
        });
      }
    } catch (error) {
      console.error('Error saving article order:', error);
      
      if (error.response?.status === 401 || error.response?.status === 403) {
        MySwal.fire({
          title: 'กรุณาเข้าสู่ระบบใหม่',
          text: 'Session หมดอายุหรือไม่มีสิทธิ์เข้าถึงระบบนี้',
          icon: 'warning',
          confirmButtonText: 'เข้าสู่ระบบ',
          confirmButtonColor: '#3085d6'
        }).then(() => {
          navigate('/adminlogin');
        });
      } else {
        MySwal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถบันทึกลำดับบทความได้',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {/* ปุ่มย้อนกลับ */}
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate("/admin/dashboard/data_management")}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      {/* หัวเรื่อง */}
      <div className="mb-8 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800">
            จัดการบทความความรู้
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            เพิ่ม ลบ และแก้ไขบทความความรู้
          </p>
        </div>
      </div>

      {/* แถบค้นหาและปุ่มเพิ่มบทความ */}
      <Card className="p-6 mb-6">
        {!isFormVisible && ( // เพิ่มเงื่อนไขการแสดงผล
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6">
            <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="text"
                placeholder="ค้นหาบทความ..."
                className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex gap-2 justify-between sm:justify-end">
              {/* เพิ่มปุ่มบันทึกลำดับ */}
              {isOrderChanged && (
                <button
                  onClick={handleSaveOrder}
                  disabled={loading}
                  className={`flex items-center gap-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <MoveVertical className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1" />
                  <span className="hidden sm:inline">บันทึกลำดับ</span>
                  <span className="sm:hidden">บันทึก</span>
                </button>
              )}
              <button
                onClick={() => {
                  resetForm();
                  setIsFormVisible(true);
                }}
                disabled={loading}
                className={`flex items-center gap-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <Plus className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
                <span className="hidden sm:inline">เพิ่มบทความ</span>
                <span className="sm:hidden">เพิ่ม</span>
              </button>
            </div>
          </div>
        )}
        
        {isFormVisible && (
          <form
            onSubmit={handleSubmit}
            className="border p-4 rounded-md text-start"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    หัวข้อบทความ <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="w-full border rounded-md px-3 py-2 text-base"
                    placeholder="ระบุหัวข้อบทความ..."
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* เพิ่มฟิลด์เนื้อหาโดยย่อ */}
                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    เนื้อหาโดยย่อ <span className="text-gray-500 text-sm">(ไม่เกิน 200 ตัวอักษร)</span>
                  </label>
                  <textarea
                    name="summary"
                    className="w-full border rounded-md px-3 py-2 text-base"
                    placeholder="เนื้อหาโดยย่อสำหรับแสดงในหน้ารายการบทความ..."
                    value={formData.summary}
                    onChange={handleChange}
                    maxLength={200}
                    rows={3}
                  />
                  <div className="text-xs text-gray-500 mt-1">
                    {formData.summary.length}/200 ตัวอักษร
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    วันที่เผยแพร่
                  </label>
                  <DatePicker
                    selected={formData.date_posted}
                    onChange={(date) => setFormData({ ...formData, date_posted: date })}
                    dateFormat="dd/MM/yyyy"
                    className="w-full border rounded-md px-3 py-2 text-base"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    ผู้เขียน
                  </label>
                  <input
                    type="text"
                    name="author"
                    className="w-full border rounded-md px-3 py-2 text-base"
                    placeholder="ระบุชื่อผู้เขียน..."
                    value={formData.author}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    หมวดหมู่
                  </label>
                  <select
                    name="category"
                    className="w-full border rounded-md px-3 py-2 text-base"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <option value="general">ทั่วไป</option>
                    <option value="technology">เทคโนโลยี</option>
                    <option value="guide">คู่มือการใช้งาน</option>
                    <option value="faq">คำถามที่พบบ่อย</option>
                    <option value="update">อัปเดตระบบ</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="is_active"
                      className="form-checkbox h-5 w-5 text-blue-600"
                      checked={formData.is_active}
                      onChange={handleChange}
                    />
                    <span className="ml-2 text-gray-700">เผยแพร่</span>
                  </label>
                </div>
              </div>

              <div>
                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    เนื้อหาบทความ <span className="text-red-500">*</span>
                  </label>
                  
                  {/* กลุ่มปุ่มการจัดรูปแบบ Markdown */}
                  <div className="mb-2">
                    <div className="flex flex-wrap gap-1 mb-2">
                      {/* กลุ่มปุ่มหัวข้อ */}
                      <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                        {buttonGroups.heading.map((example, index) => (
                          <button
                            key={`heading-${index}`}
                            type="button"
                            onClick={() => insertMarkdown(example)}
                            className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                            title={`เพิ่ม${example.label}`}
                          >
                            <Type className="h-3 w-3 mr-1" />
                            {example.label}
                          </button>
                        ))}
                      </div>
                      
                      {/* กลุ่มปุ่มหัวข้อกลาง */}
                      <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                        {buttonGroups.centered.map((example, index) => (
                          <button
                            key={`centered-${index}`}
                            type="button"
                            onClick={() => insertMarkdown(example)}
                            className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                            title={`เพิ่ม${example.label}`}
                          >
                            <AlignCenter className="h-3 w-3 mr-1" />
                            {example.label}
                          </button>
                        ))}
                      </div>
                      
                      {/* กลุ่มปุ่มตัวหนา/ตัวเอียง */}
                      <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                        {buttonGroups.format.map((example, index) => (
                          <button
                            key={`format-${index}`}
                            type="button"
                            onClick={() => insertMarkdown(example)}
                            className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                            title={`เพิ่ม${example.label}`}
                          >
                            {example.label}
                          </button>
                        ))}
                      </div>
                      
                      {/* กลุ่มปุ่มโครงสร้าง */}
                      <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                        {buttonGroups.structure.map((example, index) => (
                          <button
                            key={`structure-${index}`}
                            type="button"
                            onClick={() => insertMarkdown(example)}
                            className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                            title={`เพิ่ม${example.label}`}
                          >
                            {example.label}
                          </button>
                        ))}
                      </div>
                      
                      {/* กลุ่มปุ่มการจัดวาง */}
                      <div className="p-1 bg-gray-50 rounded-md border flex gap-1">
                        {buttonGroups.alignment.map((example, index) => (
                          <button
                            key={`alignment-${index}`}
                            type="button"
                            onClick={() => insertMarkdown(example)}
                            className="text-xs py-1 px-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 flex items-center"
                            title={`จัดข้อความ${example.label}`}
                          >
                            {example.label === "ชิดซ้าย" && <AlignLeft className="h-3 w-3 mr-1" />}
                            {example.label === "อยู่กลาง" && <AlignCenter className="h-3 w-3 mr-1" />}
                            {example.label === "ชิดขวา" && <AlignRight className="h-3 w-3 mr-1" />}
                            {example.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  
                  <textarea
                    id="article-content"
                    name="content"
                    className="w-full border rounded-md px-3 py-2 text-base min-h-[200px]"
                    placeholder="ระบุเนื้อหาบทความ... (รองรับการใช้ Markdown และ HTML)"
                    value={formData.content}
                    onChange={handleChange}
                    required
                  />

                  {/* คำแนะนำการใช้งาน Markdown */}
                  <div className="mt-1 text-xs text-gray-500">
                    <span>เคล็ดลับ: </span>
                    <span>ใช้ Enter เพื่อขึ้นบรรทัดใหม่ตามปกติได้เลย</span>
                  </div>
                  
                  {/* เพิ่มตัวอย่างการแสดงผล */}
                  {formData.content && (
                    <div className="mt-4">
                      <div className="text-sm font-medium text-gray-700 mb-2">ตัวอย่างการแสดงผล:</div>
                      <div className="border rounded-md p-4 bg-white">
                        <div className="markdown-body">
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={markdownComponents}
                          >
                            {formData.content}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* แก้ไขส่วนการจัดการรูปภาพให้มีความชัดเจนและใช้งานง่าย */}
                <div className="mb-4">
                  <label className="block text-base font-medium text-gray-700 mb-2">
                    รูปภาพประกอบ
                  </label>
                  <div className="flex items-center">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="image-upload"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="image-upload"
                      className="cursor-pointer bg-white text-blue-600 border border-blue-600 px-4 py-2 rounded-md hover:bg-blue-50 transition-colors flex items-center"
                    >
                      <ImageIcon className="h-5 w-5 mr-2" />
                      {imagePreview ? "เปลี่ยนรูปภาพ" : "อัปโหลดรูปภาพ"}
                    </label>
                    {imagePreview && (
                      <button
                        type="button"
                        className="ml-2 px-3 py-2 bg-red-50 border border-red-300 text-red-600 rounded-md hover:bg-red-100 transition-colors text-sm"
                        onClick={() => {
                          setImagePreview(null);
                          setPreviewFile(null);
                          setFormData({
                            ...formData,
                            image_url: ''
                          });
                        }}
                      >
                        ลบรูปภาพ
                      </button>
                    )}
                  </div>
                  
                  {imagePreview && (
                    <div className="mt-3 border rounded-md p-2">
                      <p className="text-sm text-gray-500 mb-2">ตัวอย่างรูปภาพ:</p>
                      <div className="relative group">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="max-h-40 max-w-full object-contain rounded"
                        />
                        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                          <a
                            href={imagePreview}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70"
                          >
                            <Eye className="h-4 w-4" />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-4 mt-6">
              <button
                type="submit"
                disabled={loading}
                className={`bg-green-600 text-white px-6 py-2.5 text-base font-medium rounded-md hover:bg-green-700 transition-colors ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "กำลังบันทึก..." : editingArticle ? "อัปเดตบทความ" : "เพิ่มบทความ"}
              </button>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  resetForm();
                  setIsFormVisible(false);
                }}
                className={`bg-gray-300 text-gray-800 px-6 py-2.5 text-base font-medium rounded-md hover:bg-gray-400 transition-colors ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      {/* แสดงรายการบทความ */}
<Card className="p-3 sm:p-6">
  {loading && articles.length === 0 ? (
    <>
      {/* Desktop View - Skeleton */}
      <div className="hidden sm:block overflow-x-auto">
        <table className="w-full table-fixed divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-[8%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
              <th scope="col" className="w-[30%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หัวข้อ</th>
              <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่โพสต์</th>
              <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้เขียน</th>
              <th scope="col" className="w-[12%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หมวดหมู่</th>
              <th scope="col" className="w-[10%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
              <th scope="col" className="w-[10%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {/* Skeleton Rows */}
            {[...Array(5)].map((_, index) => (
              <TableRowSkeleton key={index} />
            ))}
          </tbody>
        </table>
      </div>
      
      {/* Mobile View - Skeleton */}
      <div className="sm:hidden">
        <div className="space-y-3">
          {[...Array(3)].map((_, index) => (
            <MobileCardSkeleton key={index} />
          ))}
        </div>
      </div>
    </>
  ) : filteredArticles.length === 0 ? (
    <div className="px-6 py-4 text-center">ไม่พบบทความที่ค้นหา</div>
  ) : (
    <>
      {/* Desktop View - แสดงเฉพาะบนจอใหญ่ */}
      <div className="hidden sm:block overflow-x-auto">
        <table className="w-full table-fixed divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-[8%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
              <th scope="col" className="w-[30%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หัวข้อ</th>
              <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่โพสต์</th>
              <th scope="col" className="w-[15%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้เขียน</th>
              <th scope="col" className="w-[12%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หมวดหมู่</th>
              <th scope="col" className="w-[10%] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
              <th scope="col" className="w-[10%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="articles-list" type="ARTICLE">
              {(provided) => (
                <tbody 
                  className="bg-white divide-y divide-gray-200"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {/* Desktop view - existing code */}
                  {filteredArticles.map((article, index) => (
                    <Draggable 
                      key={article.article_id.toString()} 
                      draggableId={article.article_id.toString()} 
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`hover:bg-gray-50 ${snapshot.isDragging ? 'bg-gray-100' : ''} 
                          ${!article.is_active ? 'opacity-60' : ''}`}
                        >
                          {/* existing row content */}
                          <td className="px-3 py-4 whitespace-nowrap">
                            <div className="flex justify-center cursor-move">
                              <MoveVertical className="h-5 w-5 text-gray-500" />
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10 mr-4 relative bg-gray-100 rounded overflow-hidden">
                                {article.image_filename ? (
                                  <img 
                                    src={`${process.env.REACT_APP_UPLOAD_URL}/article/${article.image_filename}`} 
                                    alt=""
                                    className="h-10 w-10 object-cover"
                                    onError={(e) => {
                                      e.target.onError = null;
                                      e.target.src = `${process.env.REACT_APP_UPLOAD_URL}/public/default-image-40x40.png`;
                                    }}
                                  />
                                ) : (
                                  <FileText className="h-6 w-6 m-2 text-gray-500" />
                                )}
                              </div>
                              <div className="ml-0 overflow-hidden">
                                <div className="text-sm font-medium text-gray-900 truncate max-w-xs">
                                  {article.title}
                                </div>
                                <div className="text-xs text-gray-500 truncate max-w-xs">
                                  {article.summary ? article.summary.substring(0, 60) + (article.summary.length > 60 ? '...' : '') : ''}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex flex-col">
                              <div className="flex items-center">
                                <Calendar className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
                                <span className="text-sm text-gray-600 truncate">
                                  {article.date_posted ? format(new Date(article.date_posted), 'dd MMM yyyy') : '-'}
                                </span>
                              </div>
                              {article.updated_at && article.updated_at !== article.date_posted && (
                                <div className="flex items-center mt-1">
                                  <Clock className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
                                  <span className="text-xs text-gray-500 truncate">
                                    อัปเดต: {format(new Date(article.updated_at), 'dd MMM yyyy')}
                                  </span>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              <User className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
                              <span className="text-sm text-gray-600 truncate">{article.author || 'Admin'}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex justify-start">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 truncate max-w-full">
                                {article.category || 'ทั่วไป'}
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex justify-start">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full max-w-full ${
                                article.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                              }`}>
                                {article.is_active ? 'เผยแพร่' : 'ไม่เผยแพร่'}
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-center">
                            <div className="flex justify-center space-x-2">
                              <a
                                href={`/knowledge/${article.slug || article.article_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="p-1 text-gray-500 hover:text-gray-800 hover:bg-gray-50 rounded"
                                title="ดูตัวอย่าง"
                              >
                                <Eye className="h-5 w-5" />
                              </a>
                              <button
                                className="p-1 text-blue-600 hover:text-blue-900 hover:bg-blue-50 rounded"
                                title="แก้ไข"
                                onClick={() => handleEdit(article)}
                              >
                                <Edit2 className="h-5 w-5" />
                              </button>
                              <button
                                className="p-1 text-red-600 hover:text-red-900 hover:bg-red-50 rounded"
                                title="ลบ"
                                onClick={() => handleDelete(article.article_id)} 
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>

      {/* Mobile View - แสดงเฉพาะบนมือถือ */}
      <div className="sm:hidden">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="mobile-articles-list" type="MOBILE_ARTICLE">
            {(provided) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-3"
              >
                {filteredArticles.map((article, index) => (
                  <Draggable 
                    key={`mobile-${article.article_id.toString()}`}
                    draggableId={`mobile-${article.article_id.toString()}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'} ${!article.is_active ? 'opacity-75' : ''}`}
                      >
                        <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                          <div className="flex items-center space-x-2">
                            <span className={`px-2 py-0.5 text-xs font-medium rounded-full ${
                              article.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                            }`}>
                              {article.is_active ? 'เผยแพร่' : 'ไม่เผยแพร่'}
                            </span>
                            <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                              {article.category || 'ทั่วไป'}
                            </span>
                          </div>
                          <div {...provided.dragHandleProps} className="cursor-move">
                            <MoveVertical className="h-4 w-4 text-gray-500" />
                          </div>
                        </div>
                        
                        <div className="p-3">
                          <div className="flex items-start mb-2">
                            <div className="w-12 h-12 bg-gray-100 rounded overflow-hidden flex-shrink-0 mr-3">
                              {article.image_filename ? (
                                <img 
                                  src={`${process.env.REACT_APP_UPLOAD_URL}/article/${article.image_filename}`} 
                                  alt=""
                                  className="h-full w-full object-cover"
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = `${process.env.REACT_APP_UPLOAD_URL}/default-image-48x48.png`;
                                  }}
                                />
                              ) : (
                                <FileText className="h-6 w-6 m-3 text-gray-500" />
                              )}
                            </div>
                            <div>
                              <h3 className="font-medium text-gray-900 mb-1 text-sm line-clamp-2">
                                {article.title}
                              </h3>
                              {article.summary && (
                                <p className="text-xs text-gray-500 line-clamp-2">
                                  {article.summary}
                                </p>
                              )}
                            </div>
                          </div>
                          
                          <div className="flex flex-wrap gap-y-1.5 mt-3 text-xs text-gray-500">
                            <div className="flex items-center mr-4">
                              <Calendar className="h-3 w-3 mr-1 text-gray-400" />
                              {article.date_posted ? format(new Date(article.date_posted), 'd MMM yy') : '-'}
                            </div>
                            <div className="flex items-center">
                              <User className="h-3 w-3 mr-1 text-gray-400" />
                              {article.author || 'Admin'}
                            </div>
                            {article.updated_at && article.updated_at !== article.date_posted && (
                              <div className="flex items-center ml-auto">
                                <Clock className="h-3 w-3 mr-1 text-gray-400" />
                                แก้ไข: {format(new Date(article.updated_at), 'd MMM yy')}
                              </div>
                            )}
                          </div>
                        </div>
                        
                        <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                          <a
                            href={`/knowledge/${article.slug || article.article_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-1 bg-gray-100 text-gray-600 hover:bg-gray-200 px-3 py-1 rounded-md text-xs"
                          >
                            <Eye className="h-3 w-3" />
                            ดู
                          </a>
                          <button
                            onClick={() => handleEdit(article)}
                            className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Edit2 className="h-3 w-3" />
                            แก้ไข
                          </button>
                          <button
                            onClick={() => handleDelete(article.article_id)}
                            className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                          >
                            <Trash2 className="h-3 w-3" />
                            ลบ
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  )}
</Card>

      {/* ปรับแต่งวิธีการแสดงผลในหน้า KnowledgeManagement */}
      {/* เพิ่ม loading indicator เล็กๆ สำหรับกรณีกำลังบันทึกข้อมูลเท่านั้น */}
      {loading && isFormVisible && (
        <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-3 z-50 flex items-center">
          <div className="animate-spin rounded-full h-5 w-5 border-2 border-t-blue-600 border-blue-200 mr-2"></div>
          <span className="text-sm text-gray-700">
            {editingArticle ? "กำลังบันทึกการเปลี่ยนแปลง..." : "กำลังบันทึกข้อมูล..."}
          </span>
        </div>
      )}
    </div>
  );
};

export default KnowledgeManagement;