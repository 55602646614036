// เป็นไฟล์สำหรับฟังก์ชันช่วยเหลือและรวมฟังก์ชันพื้นฐานที่ใช้บ่อย
// จัดการการแสดง UI components เช่น icons ตาม file types
// จัดรูปแบบข้อมูล เช่น แปลงขนาดไฟล์ วันที่
// จัดการ dialogs ต่างๆ (แสดงข้อความ success/error/confirm)

import React from "react";
import { FileText, FileImage, FileSpreadsheet, File } from "lucide-react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

// เก็บ Token
export const getToken = () => {
  return localStorage.getItem('admin_token');
};

// callApi - ฟังก์ชันกลางสำหรับเรียก API
export const callApi = async (method, url, data = null, navigate) => {
  try {
    const token = getToken();
    
    if (!token) {
      throw new Error("กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
    }
    
    const config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    
    let response;
    if (method === 'get') {
      response = await axios.get(url, config);
    } else if (method === 'post') {
      response = await axios.post(url, data, config);
    } else if (method === 'delete') {
      response = await axios.delete(url, config);
    } else if (method === 'put') {
      response = await axios.put(url, data, config);
    }
    
    return response.data;
  } catch (error) {
    console.error(`API Error (${url}):`, error);
    
    if (error.response?.status === 401 || error.response?.status === 403) {
      // Token หมดอายุหรือไม่มีสิทธิ์
      MySwal.fire({
        icon: 'error',
        title: 'เซสชันหมดอายุ',
        confirmButtonColor: "#3085d6",
        text: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
      }).then(() => {
        // ลบ token และนำทางไปหน้าล็อกอิน
        localStorage.removeItem('admin_token');
        if (navigate) {
          navigate('/adminlogin');
        }
      });
    }
    
    throw error;
  }
};

// แสดงไอคอนไฟล์ตามประเภท
export const getFileIcon = (fileType) => {
  if (fileType.startsWith('image/')) {
    return <FileImage className="w-8 h-8 text-purple-500" />;
  } else if (fileType === 'application/pdf') {
    return <FileText className="w-8 h-8 text-red-500" />;
  } else if (fileType.includes('word') || fileType.includes('document')) {
    return <FileText className="w-8 h-8 text-blue-500" />;
  } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
    return <FileSpreadsheet className="w-8 h-8 text-green-500" />;
  } else {
    return <File className="w-8 h-8 text-gray-500" />;
  }
};

// แปลงขนาดไฟล์เป็น KB หรือ MB
export const formatFileSize = (bytes) => {
  if (bytes < 1024) return bytes + ' B';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else return (bytes / 1048576).toFixed(2) + ' MB';
};

// แปลงวันที่เป็นรูปแบบที่อ่านง่าย
export const formatDate = (dateString) => {
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Date(dateString).toLocaleDateString('th-TH', options);
};

// ฟังก์ชันแสดง dialog
export const showDialog = (options) => {
  return MySwal.fire(options);
};

// เพิ่ม showLoading เป็นฟังก์ชันใน showDialog
showDialog.showLoading = () => {
  return MySwal.showLoading();
};

// ฟังก์ชันแสดง loading
export const showLoading = (message) => {
  // ปิด loading ที่อาจค้างอยู่ก่อน
  MySwal.close();
  
  return MySwal.fire({
    title: message || 'กำลังดำเนินการ...',
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      MySwal.showLoading();
    }
  });
};

// ฟังก์ชันแสดง success
export const showSuccess = (message) => {
  // ปิด loading ที่อาจค้างอยู่ก่อน
  MySwal.close();
  
  return MySwal.fire({
    icon: "success",
    title: message || 'ดำเนินการสำเร็จ',
    confirmButtonColor: "#3085d6",
    timer: 1500 // เพิ่ม timer ให้ปิดอัตโนมัติหลัง 1.5 วินาที
  });
};

// ฟังก์ชันแสดง error
export const showError = (message, error) => {
  // ปิด loading ที่อาจค้างอยู่ก่อน
  MySwal.close();
  
  let errorMessage = message;
  if (error?.response?.data?.message) {
    errorMessage += `: ${error.response.data.message}`;
  }
  
  return MySwal.fire({
    icon: "error",
    title: "เกิดข้อผิดพลาด",
    text: errorMessage,
    confirmButtonColor: "#3085d6"
  });
};

// ฟังก์ชันยืนยันการลบ
export const confirmDelete = async (title, text) => {
  // ปิด loading ที่อาจค้างอยู่ก่อน
  MySwal.close();
  
  return MySwal.fire({
    title: title || "ยืนยันการลบ",
    text: text || "คุณแน่ใจหรือว่าต้องการลบ? การกระทำนี้อาจไม่สามารถย้อนกลับได้",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "ลบ",
    cancelButtonText: "ยกเลิก",
    confirmButtonColor: "#d33",
    allowOutsideClick: false,
    showLoaderOnConfirm: false
  });
};