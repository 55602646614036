import React, { useState, useEffect, useCallback } from 'react';
import { Card } from "../ui/card";
import { Plus, Search, Edit2, Trash2, ArrowLeft, MoveVertical, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { showErrorAlert, showConfirmAlert, Toast } from '../../lib/sweetAlertConfig';
import { format, isValid } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// เพิ่ม Skeleton Components

// Table Row Skeleton สำหรับตารางปกติ
const TableRowSkeleton = () => (
  <div className="flex items-center border-b py-4 animate-pulse">
    <div className="w-[10%] px-3 py-3 text-center">
      <div className="h-5 w-5 bg-gray-200 rounded-full mx-auto"></div>
    </div>
    <div className="w-[15%] px-3 py-3 text-center">
      <div className="h-4 bg-gray-200 rounded w-16 mx-auto"></div>
    </div>
    <div className="w-[35%] px-3 py-3">
      <div className="h-5 bg-gray-200 rounded w-32 mb-1"></div>
      <div className="h-4 bg-gray-200 rounded w-40"></div>
    </div>
    <div className="w-[20%] px-3 py-3">
      <div className="h-4 bg-gray-200 rounded w-20"></div>
    </div>
    <div className="w-[20%] px-3 py-3 flex justify-center space-x-2">
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
      <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
    </div>
  </div>
);

// Mobile Card Skeleton
const MobileCardSkeleton = () => (
  <div className="border rounded-lg overflow-hidden bg-white animate-pulse mb-3">
    <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
      <div className="flex items-center">
        <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-4 w-24 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center">
        <div className="h-4 w-16 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
      </div>
    </div>
    <div className="p-3">
      <div className="flex items-center mb-2">
        <div className="h-4 w-4 bg-gray-200 rounded-full mr-2"></div>
        <div className="h-4 w-32 bg-gray-200 rounded"></div>
      </div>
      <div className="ml-6 h-3 w-full bg-gray-200 rounded mb-2"></div>
      <div className="ml-6 h-3 w-3/4 bg-gray-200 rounded"></div>
    </div>
    <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
      <div className="h-6 w-16 bg-gray-200 rounded"></div>
      <div className="h-6 w-12 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    startDate: new Date(),
    endDate: '',
    description: '',
    color: '#3b82f6',
    allDay: false
  });
  const navigate = useNavigate();

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/events?t=${new Date().getTime()}`);
      
      const validatedEvents = response.data.map(event => ({
        ...event,
        start: event.start || null,
        end: event.end || null
      }));
      
      setEvents(validatedEvents);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('ไม่สามารถโหลดข้อมูลกิจกรรมได้');
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const resetForm = () => {
    setEditingEvent(null);
    setFormData({
      title: '',
      startDate: new Date(),
      endDate: '',
      description: '',
      color: '#3b82f6',
      allDay: false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      showErrorAlert('ข้อมูลไม่ครบถ้วน', 'กรุณากรอกชื่อกิจกรรม');
      return;
    }
    
    try {
      setLoading(true);
      
      let eventData = {
        title: formData.title,
        startDate: formData.startDate ? format(formData.startDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        endDate: formData.allDay ? null : 
                formData.endDate ? format(formData.endDate, 'yyyy-MM-dd') : null,
        description: formData.description,
        color: formData.color,
        allDay: formData.allDay
      };

      if (editingEvent) {
        await axios.put(`/events/${editingEvent.id}`, eventData);
        fetchEvents(); 
      } else {
        await axios.post('/events', eventData);
        fetchEvents();
      }

      setIsFormVisible(false);
      setFormData({
        title: '',
        startDate: new Date(),
        endDate: null,
        description: '',
        color: '#3b82f6',
        allDay: false
      });
      setEditingEvent(null);

      Toast.fire({
        icon: 'success',
        title: editingEvent ? 'แก้ไขกิจกรรมเรียบร้อยแล้ว' : 'เพิ่มกิจกรรมเรียบร้อยแล้ว'
      });
      
    } catch (error) {
      console.error('Error saving event:', error);
      showErrorAlert('เกิดข้อผิดพลาด', 'ไม่สามารถบันทึกข้อมูลได้');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await showConfirmAlert('คุณแน่ใจหรือไม่?', 'คุณจะไม่สามารถย้อนกลับได้!');
    
    if (result.isConfirmed) {
      try {
        setLoading(true);
        
        const response = await axios.delete(`/events/${id}`);
        
        if (response.status === 200) {
          // เรียกข้อมูลใหม่หลังลบสำเร็จ
          await fetchEvents();
          
          // แสดง Toast เมื่อลบสำเร็จ
          Toast.fire({
            icon: 'success',
            title: 'ลบกิจกรรมเรียบร้อยแล้ว'
          });
        } else {
          throw new Error('ไม่สามารถลบข้อมูลได้');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
        showErrorAlert('เกิดข้อผิดพลาด', 'ไม่สามารถลบกิจกรรมได้');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (event) => {
    setEditingEvent(event);
    
    const startDate = event.start ? new Date(event.start) : new Date();
    let endDate = null;
    
    if (event.end) {
      endDate = new Date(event.end);
      if (endDate <= startDate) {
        console.warn("End date is not greater than start date, resetting end date");
        endDate = null;
      }
    }

    setFormData({
      title: event.title || '',
      startDate: startDate,
      endDate: endDate,
      description: event.description || '',
      color: event.color || event.backgroundColor || '#3b82f6',
      allDay: event.allDay === undefined ? false : event.allDay
    });
    
    setIsFormVisible(true);
  };
  
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(events);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    const updatedItems = items.map((item, index) => {
      return {
        ...item,
        order_position: index + 1
      };
    });
    
    setEvents(updatedItems);
    setIsOrderChanged(true);
    
    console.log('After drag order:', updatedItems.map(i => ({ id: i.id, pos: i.order_position })));
  };

  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      
      const orderData = events.map((item, index) => {
        const id = typeof item.id === 'string' ? parseInt(item.id, 10) : item.id;
        return {
          id: id,
          order_position: index + 1
        };
      });
      
      console.log('Sending order data to API:', orderData);

      const response = await axios({
        method: 'post',
        url: '/events/reorder',
        data: {
          eventOrders: orderData
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('API response:', response.data);

      if (response.data.success) {
        setIsOrderChanged(false);
        await fetchEvents();
        
        Toast.fire({
          icon: 'success',
          title: 'บันทึกลำดับกิจกรรมเรียบร้อยแล้ว'
        });
      } else {
        throw new Error(response.data.message || 'บันทึกลำดับไม่สำเร็จ');
      }
    } catch (error) {
      console.error('Error saving order:', error);
      showErrorAlert('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกลำดับกิจกรรมได้');
    } finally {
      setLoading(false);
    }
  };

  const filteredEvents = events.filter(event => 
    event.title && event.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-end items-center">
        <button
          onClick={() => navigate('/admin/dashboard/data_management')}
          className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
        >
          <ArrowLeft className="h-5 w-5" />
          ย้อนกลับ
        </button>
      </div>

      <div className="mb-8 flex justify-center items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">จัดการปฏิทินกิจกรรม</h1>
          <p className="text-gray-600 mt-2">เพิ่ม แก้ไข และลบกิจกรรมในปฏิทิน</p>
        </div>
      </div>

      <Card className="p-6 mb-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="ค้นหากิจกรรม..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:ring-gray-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2 justify-between sm:justify-end">
            {isOrderChanged && (
              <button
                onClick={handleSaveOrder}
                className="flex items-center gap-1 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors text-xs sm:text-base"
              >
                <MoveVertical className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1" />
                <span className="hidden sm:inline">บันทึกลำดับ</span>
                <span className="sm:hidden">บันทึก</span>
              </button>
            )}
            <button
              onClick={() => {
                resetForm();
                setIsFormVisible(true);
              }}
              className="flex items-center gap-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors text-xs sm:text-base"
            >
              <Plus className="h-3.5 w-3.5 sm:h-5 sm:w-5" />
              <span className="hidden sm:inline">เพิ่มกิจกรรม</span>
              <span className="sm:hidden">เพิ่ม</span>
            </button>
          </div>
        </div>

        {isFormVisible && (
          <form onSubmit={handleSubmit} className="mb-6 border p-4 rounded-md text-start">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">ชื่อกิจกรรม</label>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2"
                placeholder="ระบุชื่อกิจกรรม..."
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                required
              />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">วันที่เริ่มต้น</label>
                <DatePicker
                  selected={formData.startDate instanceof Date ? formData.startDate : null}
                  onChange={(date) => setFormData({ ...formData, startDate: date })}
                  dateFormat="dd/MM/yyyy"
                  className="w-full border rounded-md px-3 py-2"
                  placeholderText="เลือกวันที่เริ่มต้น..."
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">วันที่สิ้นสุด (ถ้ามี)</label>
                <DatePicker
                  selected={formData.endDate instanceof Date ? formData.endDate : null}
                  onChange={(date) => setFormData({ ...formData, endDate: date })}
                  dateFormat="dd/MM/yyyy"
                  className="w-full border rounded-md px-3 py-2"
                  placeholderText="เลือกวันที่สิ้นสุด..."
                  disabled={formData.allDay}
                />
                {!formData.allDay && !formData.endDate && (
                  <p className="text-xs text-red-500 mt-1">
                    กรุณาเลือกวันที่สิ้นสุด หรือเลือกตัวเลือก "ทั้งวัน"
                  </p>
                )}
              </div>
            </div>
        
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">สี</label>
                <input
                  type="color"
                  className="w-10 h-10 border rounded-md px-1 py-1"
                  value={formData.color}
                  onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                />
              </div>
              <div className="flex items-center mt-7">
                <input
                  type="checkbox"
                  id="allDay"
                  checked={formData.allDay}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormData({ 
                        ...formData, 
                        allDay: e.target.checked,
                        endDate: null
                      });
                    } else {
                      setFormData({ 
                        ...formData, 
                        allDay: e.target.checked 
                      });
                    }
                  }}
                  className="mr-4 h-6 w-6"
                />
                <label htmlFor="allDay" className="text-sm font-medium text-gray-700">
                  ทั้งวัน (ไม่ระบุวันสิ้นสุด)
                </label>
              </div>
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">รายละเอียด (ถ้ามี)</label>
              <textarea
                className="w-full border rounded-md px-3 py-2"
                placeholder="รายละเอียดกิจกรรม..."
                rows="3"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
            </div>
            
            <div className="flex gap-4">
              {(formData.allDay || (formData.endDate && formData.endDate instanceof Date)) ? (
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
                >
                  บันทึก
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-gray-400 text-white px-4 py-2 rounded-md cursor-not-allowed"
                  disabled
                  title="กรุณาเลือกวันที่สิ้นสุด หรือเลือกตัวเลือก 'ทั้งวัน'"
                >
                  บันทึก
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  setIsFormVisible(false);
                  resetForm();
                }}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
              >
                ยกเลิก
              </button>
            </div>
          </form>
        )}
      </Card>

      <Card className="p-3 sm:p-6 overflow-hidden">
        {loading ? (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</div>
                  <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
                  <div className="w-[40%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อกิจกรรม</div>
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สี</div>
                  <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
                </div>
                
                {[...Array(5)].map((_, index) => (
                  <TableRowSkeleton key={index} />
                ))}
              </div>
            </div>
            
            <div className="sm:hidden">
              {[...Array(3)].map((_, index) => (
                <MobileCardSkeleton key={index} />
              ))}
            </div>
          </>
        ) : error ? (
          <div className="px-6 py-8 text-center text-red-500">{error}</div>
        ) : filteredEvents.length === 0 ? (
          <div className="px-6 py-8 text-center text-gray-500">ไม่มีกิจกรรมในระบบ</div>
        ) : (
          <>
            <div className="hidden sm:block overflow-x-auto">
              <div className="min-w-full">
                <div className="flex bg-gray-50 border-b">
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</div>
                  <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่</div>
                  <div className="w-[40%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อกิจกรรม</div>
                  <div className="w-[10%] px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สี</div>
                  <div className="w-[20%] px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</div>
                </div>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="events-list" type="EVENT">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="bg-white"
                      >
                        {filteredEvents.map((event, index) => (
                          <Draggable 
                            key={event.id.toString()} 
                            draggableId={event.id.toString()} 
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`flex items-center border-b hover:bg-gray-50 min-h-[80px] ${
                                  snapshot.isDragging ? 'bg-gray-100' : ''
                                }`}
                              >
                                <div className="w-[10%] px-3 py-4 whitespace-nowrap text-center">
                                  <div className="flex justify-center cursor-move">
                                    <MoveVertical className="h-5 w-5 text-gray-500" />
                                  </div>
                                </div>

                                <div className="w-[20%] px-6 py-4 flex justify-center">
                                  <div className="flex flex-col items-center">
                                    <span className="font-medium">
                                      {event.start && isValid(new Date(event.start)) 
                                        ? format(new Date(event.start), 'd MMM yyyy') 
                                        : 'ไม่ระบุวันที่'}
                                    </span>
                                    {event.end && !event.allDay && (
                                      <span className="text-xs text-gray-500 mt-1">
                                        ถึง {isValid(new Date(event.end)) 
                                          ? format(new Date(event.end), 'd MMM yyyy')
                                          : ''}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="w-[40%] px-6 py-4">
                                  <div className="flex items-center">
                                    <Calendar className="h-5 w-5 mr-2 text-gray-500 flex-shrink-0" />
                                    <span className="font-medium">{event.title || 'ไม่มีชื่อ'}</span>
                                  </div>
                                  {event.description && (
                                    <div className="mt-1 pl-7 text-sm text-gray-500 line-clamp-1">
                                      {event.description}
                                    </div>
                                  )}
                                </div>

                                <div className="w-[10%] px-3 py-4 flex justify-center">
                                  <div 
                                    className="w-6 h-6 rounded-full flex-shrink-0" 
                                    style={{ backgroundColor: event.backgroundColor || event.color || '#3b82f6' }}
                                  ></div>
                                </div>

                                <div className="w-[20%] px-6 py-4 flex justify-center">
                                  <div className="flex space-x-3">
                                    <button
                                      onClick={() => handleEdit(event)}
                                      className="text-blue-600 hover:text-blue-900 p-1 rounded-full hover:bg-blue-50"
                                    >
                                      <Edit2 className="h-5 w-5" />
                                    </button>
                                    <button
                                      onClick={() => handleDelete(event.id)}
                                      className="text-red-600 hover:text-red-900 p-1 rounded-full hover:bg-red-50"
                                    >
                                      <Trash2 className="h-5 w-5" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            
            <div className="sm:hidden">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="mobile-events-list" type="MOBILE_EVENT">
                  {(provided) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="space-y-3"
                    >
                      {filteredEvents.map((event, index) => (
                        <Draggable 
                          key={`mobile-${event.id.toString()}`} 
                          draggableId={`mobile-${event.id.toString()}`} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`border rounded-lg overflow-hidden ${snapshot.isDragging ? 'bg-gray-100' : 'bg-white'}`}
                            >
                              <div className="p-3 bg-gray-50 border-b flex justify-between items-center">
                                <div className="flex items-center">
                                  <div 
                                    className="w-4 h-4 rounded-full mr-2" 
                                    style={{ backgroundColor: event.backgroundColor || event.color || '#3b82f6' }}
                                  ></div>
                                  <div className="text-xs font-medium text-gray-600">
                                    {event.start && isValid(new Date(event.start)) 
                                      ? format(new Date(event.start), 'd MMM yyyy') 
                                      : 'ไม่ระบุวันที่'}
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  {event.allDay ? (
                                    <span className="text-xs bg-blue-100 text-blue-800 rounded-full px-2 py-0.5 mr-2">
                                      ทั้งวัน
                                    </span>
                                  ) : event.end ? (
                                    <span className="text-xs bg-blue-100 text-blue-800 rounded-full px-2 py-0.5 mr-2">
                                      ถึง {isValid(new Date(event.end)) ? format(new Date(event.end), 'd/M/yy') : ''}
                                    </span>
                                  ) : null}
                                  <div {...provided.dragHandleProps} className="cursor-move">
                                    <MoveVertical className="h-4 w-4 text-gray-500" />
                                  </div>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="flex items-center mb-2">
                                  <Calendar className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0" />
                                  <h3 className="font-medium text-gray-800">{event.title || 'ไม่มีชื่อ'}</h3>
                                </div>
                                {event.description && (
                                  <p className="text-xs text-gray-600 mb-2 ml-6 line-clamp-2">
                                    {event.description}
                                  </p>
                                )}
                              </div>
                              <div className="p-3 bg-gray-50 border-t flex justify-end space-x-2">
                                <button
                                  onClick={() => handleEdit(event)}
                                  className="flex items-center gap-1 bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded-md text-xs"
                                >
                                  <Edit2 className="h-3 w-3" />
                                  แก้ไข
                                </button>
                                <button
                                  onClick={() => handleDelete(event.id)}
                                  className="flex items-center gap-1 bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded-md text-xs"
                                >
                                  <Trash2 className="h-3 w-3" />
                                  ลบ
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default EventManagement;