import React, { useState, useEffect } from 'react';
import { Card } from '../ui/card';
import { 
  ArrowLeft, Calendar, Clock, Smartphone, User,
  BarChart4, LineChart, PieChart, Activity, RefreshCw
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import { Toast, showDeleteConfirmAlert } from '../../lib/sweetAlertConfig'; // Import SweetAlert utilities

// ลงทะเบียน Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// เพิ่มฟังก์ชันเช็คขนาดหน้าจอ
const useResponsiveAspectRatio = () => {
  const [aspectRatio, setAspectRatio] = useState(() => {
    if (window.innerWidth > 1600) return 2.5;
    if (window.innerWidth > 1280) return 2;
    return 1.5;
  });
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600) setAspectRatio(2.5);
      else if (window.innerWidth > 1280) setAspectRatio(2);
      else setAspectRatio(1.5);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return aspectRatio;
};

// คงไว้เนื่องจากเป็นเพียงการจัดรูปแบบให้อ่านง่ายขึ้น ไม่ได้เกี่ยวกับ timezone
const formatThaiHour = (hour) => {
  // ไม่ต้องปรับ hours เพิ่ม 7 อีกเพราะข้อมูลจาก API เป็น GMT+7 อยู่แล้ว
  return `${hour.toString().padStart(2, '0')}:00 น.`;
};

// แก้ไขฟังก์ชัน formatDateForDisplay
const formatDateForDisplay = (dbDateString) => {
  if (!dbDateString) return '-';
  
  try {
    // ตรวจสอบรูปแบบวันที่ที่ได้รับ
    if (dbDateString.endsWith('Z') || dbDateString.includes('+')) {
      // กรณีเป็น ISO string
      const date = new Date(dbDateString);
      return date.toLocaleString('th-TH', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false

      });
    } else {
      // กรณีไม่ใช่ ISO string (เช่น "YYYY-MM-DD HH:MM:SS")
      const date = new Date(dbDateString);
      if (isNaN(date.getTime())) {
        return dbDateString; // ถ้าแปลงไม่ได้ ให้แสดงข้อมูลเดิม
      }
      
      return date.toLocaleString('th-TH', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false 
      });
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return dbDateString;
  }
};

// เพิ่ม Skeleton Components สำหรับการโหลดข้อมูล
const MetricCardSkeleton = () => (
  <div className="p-4 bg-white border rounded-lg animate-pulse">
    <div className="flex items-center justify-between">
      <div>
        <div className="h-3 w-24 bg-gray-200 rounded mb-2"></div>
        <div className="h-6 w-16 bg-gray-200 rounded"></div>
      </div>
      <div className="bg-gray-200 p-3 rounded-full h-12 w-12"></div>
    </div>
  </div>
);

const ChartSkeleton = () => (
  <Card className="p-4 animate-pulse">
    <div className="h-5 w-40 bg-gray-200 rounded mb-4"></div>
    <div className="h-60 md:h-72 bg-gray-100 rounded-lg flex items-center justify-center">
      <div className="text-gray-400 flex flex-col items-center">
        <BarChart4 className="h-10 w-10 mb-3 opacity-25" />
        <div className="h-3 w-32 bg-gray-200 rounded"></div>
      </div>
    </div>
  </Card>
);

const TableRowSkeleton = ({ columns = 5 }) => (
  <tr className="animate-pulse">
    {[...Array(columns)].map((_, i) => (
      <td key={i} className="px-3 sm:px-6 py-4 whitespace-nowrap">
        <div className="h-4 bg-gray-200 rounded w-full max-w-[120px]"></div>
      </td>
    ))}
  </tr>
);

const TableSkeleton = ({ rows = 5, columns = 5 }) => (
  <div className="overflow-x-auto animate-pulse">
    <table className="min-w-full divide-y divide-gray-200 text-left">
      <thead className="bg-gray-50">
        <tr>
          {[...Array(columns)].map((_, i) => (
            <th key={i} scope="col" className="px-3 sm:px-6 py-3 text-left">
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {[...Array(rows)].map((_, i) => (
          <TableRowSkeleton key={i} columns={columns} />
        ))}
      </tbody>
    </table>
  </div>
);

// เพิ่ม SkeletonCharts สำหรับแสดงในแต่ละ tab
const OverviewTabSkeleton = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
    <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 grid grid-cols-2 md:grid-cols-4 gap-4">
      {[...Array(4)].map((_, i) => (
        <MetricCardSkeleton key={i} />
      ))}
    </div>
    {[...Array(4)].map((_, i) => (
      <ChartSkeleton key={i} />
    ))}
  </div>
);

const UsersTabSkeleton = () => (
  <div className="space-y-6">
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-60 bg-gray-200 rounded mb-4"></div>
      <div className="max-w-4xl mx-auto">
        <div className="h-64 md:h-80 bg-gray-100 rounded-lg flex items-center justify-center">
          <div className="text-gray-400 flex flex-col items-center">
            <BarChart4 className="h-10 w-10 mb-3 opacity-25" />
            <div className="h-4 w-40 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </Card>
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-40 bg-gray-200 rounded mb-4"></div>
      <TableSkeleton rows={8} columns={5} />
    </Card>
  </div>
);

const TrendsTabSkeleton = () => (
  <div className="space-y-6">
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-48 bg-gray-200 rounded mb-6"></div>
      <div className="max-w-5xl mx-auto">
        <div className="h-64 md:h-80 mb-8 bg-gray-100 rounded-lg flex items-center justify-center">
          <div className="text-gray-400 flex flex-col items-center">
            <LineChart className="h-10 w-10 mb-3 opacity-25" />
            <div className="h-4 w-56 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-gray-50 rounded-lg p-4 border animate-pulse">
            <div className="h-3 w-28 bg-gray-200 rounded mb-2"></div>
            <div className="h-6 w-16 bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>
    </Card>
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-64 bg-gray-200 rounded mb-6"></div>
      <div className="max-w-4xl mx-auto">
        <div className="h-64 md:h-80 bg-gray-100 rounded-lg flex items-center justify-center">
          <div className="text-gray-400 flex flex-col items-center">
            <BarChart4 className="h-10 w-10 mb-3 opacity-25" />
            <div className="h-4 w-48 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </Card>
  </div>
);

const DevicesTabSkeleton = () => (
  <div className="space-y-6">
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-40 bg-gray-200 rounded mb-4"></div>
      <div className="max-w-4xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(2)].map((_, i) => (
            <div key={i} className="h-64 md:h-80 bg-gray-100 rounded-lg flex items-center justify-center">
              <div className="text-gray-400 flex flex-col items-center">
                <PieChart className="h-10 w-10 mb-3 opacity-25" />
                <div className="h-4 w-36 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
    <Card className="p-4 sm:p-6">
      <div className="h-6 w-56 bg-gray-200 rounded mb-4"></div>
      <TableSkeleton rows={6} columns={3} />
    </Card>
  </div>
);

const RecentLoginsTabSkeleton = () => (
  <Card className="p-4 sm:p-6">
    <div className="h-6 w-40 bg-gray-200 rounded mb-6"></div>
    <TableSkeleton rows={10} columns={5} />
  </Card>
);

const LoginStatistics = () => {
  const [stats, setStats] = useState({
    userLoginStats: [],
    dailyStats: [],
    hourlyStats: [],
    deviceStats: [],
    recentLogins: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();
  const aspectRatio = useResponsiveAspectRatio();

  useEffect(() => {
    // ตรวจสอบว่ามี admin_token หรือไม่
    const token = localStorage.getItem('admin_token');
    if (!token) {
      navigate('/adminlogin');
      return;
    }

    // เรียกข้อมูลสถิติการล็อกอิน
    const fetchLoginStatistics = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/admin/statistics/login', {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        console.log('Raw API response:', response.data.recentLogins[0]?.login_time);
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching login statistics:', error);
        if (error.response?.status === 404) {
          setError('ไม่พบ API endpoint สำหรับดึงข้อมูลสถิติ กรุณาตรวจสอบการตั้งค่าในไฟล์ server.js');
        } else {
          setError(`ไม่สามารถโหลดข้อมูลสถิติได้: ${error.message}`);
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    };

    fetchLoginStatistics();
  }, [navigate]);

  const handleClearLogs = async () => {
    const result = await showDeleteConfirmAlert(
      "คุณแน่ใจหรือไม่?",
      "การลบข้อมูล Log ทั้งหมดจะไม่สามารถกู้คืนได้!"
    );

    if (result.isConfirmed) {
      try {
        const token = localStorage.getItem('admin_token');
        if (!token) {
          Toast.fire({
            icon: 'error',
            title: 'กรุณาเข้าสู่ระบบอีกครั้ง'
          });
          navigate('/adminlogin');
          return;
        }

        await axios.delete('/admin/statistics/login/clear', {
          headers: { Authorization: `Bearer ${token}` }
        });

        Toast.fire({
          icon: 'success',
          title: 'ลบข้อมูล Log สำเร็จ'
        });

        window.location.reload();
      } catch (error) {
        console.error("Error clearing logs:", error);
        Toast.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาดในการลบข้อมูล Log'
        });
      }
    }
  };

  // Data preparation for charts (ส่วนเตรียมข้อมูลสำหรับกราฟต่างๆ คงเดิม)
  const topUsersData = {
    labels: stats.userLoginStats?.map(user => user.username || `User #${user.member_id}`) || [],
    datasets: [
      {
        label: 'จำนวนครั้งที่ล็อกอิน',
        data: stats.userLoginStats?.map(user => user.login_count) || [],
        backgroundColor: '#3b82f6',
        borderColor: '#2563eb',
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  // แก้ไขส่วนที่สร้างข้อมูลสำหรับกราฟแนวโน้มรายวัน
const dailyTrendData = {
  labels: stats.dailyStats?.map(day => {
    if (!day.login_date) return '';
    
    // สร้าง label ที่อ่านง่ายจากวันที่โดยตรง ไม่ผ่าน Date object
    const dateParts = day.login_date.split('-');
    if (dateParts.length === 3) {
      const thaiMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
      return `${parseInt(dateParts[2])} ${thaiMonths[parseInt(dateParts[1])]}`;
    }
    return day.login_date;
  }) || [],
  datasets: [
    {
      label: 'จำนวนการล็อกอินรายวัน',
      data: stats.dailyStats?.map(day => day.login_count) || [],
      fill: true,
      backgroundColor: 'rgba(59, 130, 246, 0.2)',
      borderColor: '#3b82f6',
      tension: 0.4,
      pointRadius: 4,
      pointBackgroundColor: '#2563eb',
    },
  ],
};

  // ปรับปรุงการสร้างข้อมูลสำหรับ hourlyData
  const hourlyData = {
    labels: stats.hourlyStats?.map(hour => {
      // ใช้ฟังก์ชัน formatThaiHour เพื่อให้รูปแบบตรงกันทุกที่
      return formatThaiHour(hour.hour_of_day);
    }) || [],
    datasets: [
      {
        label: 'จำนวนการล็อกอิน',
        data: stats.hourlyStats?.map(hour => hour.login_count) || [],
        backgroundColor: '#10b981',
        borderColor: '#059669',
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const deviceData = {
    labels: stats.deviceStats?.map(device => device.device) || [],
    datasets: [
      {
        data: stats.deviceStats?.map(device => device.count) || [],
        backgroundColor: ['#3b82f6', '#ef4444', '#10b981', '#f59e0b', '#8b5cf6', '#6b7280'],
        borderColor: '#ffffff',
        borderWidth: 2,
      },
    ],
  };

  // ตั้งค่า options สำหรับกราฟต่างๆ (คงเดิม)
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: aspectRatio,
    plugins: {
      legend: {
        display: false, // ซ่อนตำนานบนกราฟแท่งเพื่อให้ดูดีบนมือถือ
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `จำนวนล็อกอิน: ${context.parsed.y} ครั้ง`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0
        }
      },
      x: {
        ticks: {
          // แสดงฉลากน้อยลงบนอุปกรณ์เล็ก
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 8
        }
      }
    }
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // ซ่อนตำนานบนกราฟเส้นเพื่อให้ดูดีบนมือถือ
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `จำนวนล็อกอิน: ${context.parsed.y} ครั้ง`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0
        }
      },
      x: {
        ticks: {
          // แสดงฉลากน้อยลงบนอุปกรณ์เล็ก
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 7
        }
      }
    }
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom', // สำหรับมือถือแสดงตำนานด้านล่าง
        labels: {
          boxWidth: 12, // ลดขนาดช่องสี่เหลี่ยมของตำนาน
          padding: 10 // เพิ่มระยะห่างระหว่างรายการ
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            const percentage = Math.round((context.parsed * 100) / total);
            return `${context.label}: ${context.parsed} (${percentage}%)`;
          }
        }
      }
    }
  };



  // เรนเดอร์ข้อผิดพลาด
  const renderError = () => (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md mb-6">
      <p className="font-medium">เกิดข้อผิดพลาด</p>
      <p>{error}</p>
      <button 
        onClick={() => window.location.reload()}
        className="mt-2 flex items-center gap-2 text-red-700 hover:text-red-800 font-medium"
      >
        <RefreshCw className="h-4 w-4" /> โหลดข้อมูลใหม่
      </button>
    </div>
  );

  // เรนเดอร์ content ตาม tab ที่เลือก
  const renderContent = () => {
    if (loading) {
      switch (activeTab) {
        case 'overview':
          return <OverviewTabSkeleton />;
        case 'users':
          return <UsersTabSkeleton />;
        case 'trends':
          return <TrendsTabSkeleton />;
        case 'devices':
          return <DevicesTabSkeleton />;
        case 'recent':
          return <RecentLoginsTabSkeleton />;
        default:
          return <OverviewTabSkeleton />;
      }
    }
    
    if (error) {
      return renderError();
    }

    switch (activeTab) {
      case 'overview':
        return renderOverview();
      case 'users':
        return renderUserStats();
      case 'trends':
        return renderTrends();
      case 'devices':
        return renderDevices();
      case 'recent':
        return renderRecentLogins();
      default:
        return renderOverview();
    }
  };

  // ปรับปรุงฟังก์ชันสำหรับแสดงผล tab ต่างๆ ให้รองรับการแสดงผลบนมือถือ

  // 1. ปรับขนาดคอลัมน์ให้เหมาะสมและมีความยืดหยุ่นมากขึ้น
const renderOverview = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
    {/* ข้อมูลสรุปตัวเลขสำคัญ (Key metrics) */}
    <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 grid grid-cols-2 md:grid-cols-4 gap-4">
      <Card className="p-4 bg-blue-50 border border-blue-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-500">การล็อกอินทั้งหมด</p>
            <p className="text-2xl font-bold text-gray-800 mt-1">
              {stats.dailyStats?.reduce((sum, day) => sum + day.login_count, 0) || 0}
            </p>
          </div>
          <div className="bg-blue-100 p-3 rounded-full">
            <Activity className="h-6 w-6 text-blue-600" />
          </div>
        </div>
      </Card>
      
      <Card className="p-4 bg-green-50 border border-green-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-500">ล็อกอินวันนี้</p>
            <p className="text-2xl font-bold text-gray-800 mt-1">
              {stats.dailyStats?.find(day => {
                // เปรียบเทียบวันที่โดยตรงจาก string ไม่ใช่ Date object
                const today = new Date().toISOString().split('T')[0]; // รูปแบบ YYYY-MM-DD
                return day.login_date === today;
              })?.login_count || 0}
            </p>
          </div>
          <div className="bg-green-100 p-3 rounded-full">
            <Calendar className="h-6 w-6 text-green-600" />
          </div>
        </div>
      </Card>
      
      <Card className="p-4 bg-purple-50 border border-purple-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-500">ผู้ใช้ที่ล็อกอิน</p>
            <p className="text-2xl font-bold text-gray-800 mt-1">
              {stats.userLoginStats?.length || 0}
            </p>
          </div>
          <div className="bg-purple-100 p-3 rounded-full">
            <User className="h-6 w-6 text-purple-600" />
          </div>
        </div>
      </Card>
      
      <Card className="p-4 bg-orange-50 border border-orange-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-500">ช่วงเวลาที่ใช้มากสุด</p>
            <p className="text-2xl font-bold text-gray-800 mt-1">
              {stats.hourlyStats?.length > 0 ? formatThaiHour(stats.hourlyStats.reduce((max, hour) => 
                hour.login_count > max.count ? {hour: hour.hour_of_day, count: hour.login_count} : max, 
                {hour: 0, count: 0}
              ).hour) : '-'}
            </p>
          </div>
          <div className="bg-orange-100 p-3 rounded-full">
            <Clock className="h-6 w-6 text-orange-600" />
          </div>
        </div>
      </Card>
    </div>

    {/* แก้ไขกราฟต่างๆ ให้มีขนาดเหมาะสมมากขึ้น */}
    <Card className="p-4 md:col-span-1 lg:col-span-1">
      <h3 className="text-lg font-semibold mb-2 flex items-center">
        <User className="h-5 w-5 mr-2 text-indigo-600" />
        <span>ผู้ใช้งานที่เข้าสู่ระบบมากที่สุด</span>
      </h3>
      <div className="h-60 md:h-72">
        <Bar data={topUsersData} options={{
          ...barChartOptions,
          maintainAspectRatio: true, 
          aspectRatio: 1.5
        }} />
      </div>
    </Card>
    
    <Card className="p-4 md:col-span-1 lg:col-span-1">
      <h3 className="text-lg font-semibold mb-2 flex items-center">
        <LineChart className="h-5 w-5 mr-2 text-indigo-600" />
        <span>แนวโน้มการล็อกอินช่วง 30 วัน</span>
      </h3>
      <div className="h-60 md:h-72">
        <Line data={dailyTrendData} options={{
          ...lineChartOptions,
          maintainAspectRatio: true,
          aspectRatio: 1.5
        }} />
      </div>
    </Card>
    
    <Card className="p-4 md:col-span-1 lg:col-span-1">
      <h3 className="text-lg font-semibold mb-2 flex items-center">
        <Clock className="h-5 w-5 mr-2 text-indigo-600" />
        <span>ช่วงเวลาที่มีการล็อกอินมากที่สุด</span>
      </h3>
      <div className="h-60 md:h-72">
        <Bar data={hourlyData} options={{
          ...barChartOptions,
          maintainAspectRatio: true,
          aspectRatio: 1.5,
          plugins: {
            ...barChartOptions.plugins,
            tooltip: {
              callbacks: {
                title: function(tooltipItems) {
                  return tooltipItems[0].label; // แสดงเวลาตามรูปแบบ formatThaiHour
                },
                label: function(context) {
                  return `จำนวนล็อกอิน: ${context.parsed.y} ครั้ง`;
                }
              }
            }
          },
          scales: {
            ...barChartOptions.scales,
            x: {
              ...barChartOptions.scales.x,
              ticks: {
                ...barChartOptions.scales.x.ticks,
                callback: function(value, index) {
                  return hourlyData.labels[index];
                }
              }
            } 
          }
        }} />
      </div>
    </Card>
    
    
    <Card className="p-4 md:col-span-1 lg:col-span-1">
      <h3 className="text-lg font-semibold mb-2 flex items-center">
        <Smartphone className="h-5 w-5 mr-2 text-indigo-600" />
        <span>อุปกรณ์ที่ใช้งาน</span>
      </h3>
      <div className="h-60 md:h-72">
        <Doughnut data={deviceData} options={{
          ...pieChartOptions,
          maintainAspectRatio: true,
          aspectRatio: 1.5
        }} />
      </div>
    </Card>
  </div>
);

  // 2. ปรับปรุงการแสดงข้อมูลผู้ใช้
const renderUserStats = () => (
  <div className="space-y-6">
    {/* แก้ไขขนาดกราฟแท่ง */}
    <Card className="p-4 sm:p-6">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <BarChart4 className="h-6 w-6 mr-2 text-indigo-600" />
        <span>ผู้ใช้งานที่เข้าสู่ระบบมากที่สุด</span>
      </h3>
      
      {/* แก้ไขส่วนนี้ให้มีขนาดจำกัดมากขึ้น */}
      <div className="max-w-4xl mx-auto">
        <div className="h-64 md:h-80">
          <Bar data={topUsersData} options={{
            ...barChartOptions,
            maintainAspectRatio: true,
            aspectRatio: window.innerWidth > 1280 ? 2 : 1.5,
            plugins: {
              ...barChartOptions.plugins,
              title: {
                display: true,
                text: '10 อันดับผู้ใช้งานที่มีการล็อกอินมากที่สุด',
                font: { size: 16 }
              }
            }
          }} />
        </div>
      </div>
    </Card>
    
    {/* ตารางยังคงความกว้างเดิม เพราะต้องการแสดงข้อมูลทั้งหมด */}
    <Card className="p-4 sm:p-6">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <User className="h-6 w-6 mr-2 text-indigo-600" />
        <span>รายละเอียดผู้ใช้</span>
      </h3>
      {/* ตารางแสดงข้อมูลผู้ใช้ที่ปรับให้เหมาะกับขนาดหน้าจอ */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 text-left">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อผู้ใช้</th>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัส</th>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวน</th>
              <th scope="col" className="hidden md:table-cell px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ล็อกอินล่าสุด
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {stats.userLoginStats?.map((user, index) => (
              <tr key={user.member_id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-900">{user.username || '-'}</td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">{user.member_id}</td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-900">{user.login_count}</td>
                <td className="hidden md:table-cell px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDateForDisplay(user.last_login)}
                </td>
              </tr>
            ))}
            {!stats.userLoginStats?.length && (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-500">
                  ไม่พบข้อมูลผู้ใช้งาน
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* แสดงเพิ่มเติมสำหรับมือถือ - คลิกที่แถวเพื่อดูรายละเอียด */}
      <div className="md:hidden mt-4 text-sm text-gray-500 text-center">
        <p>เลื่อนซ้ายขวาเพื่อดูข้อมูลเพิ่มเติม</p>
      </div>
    </Card>
  </div>
);

// 3. ปรับปรุงการแสดงแนวโน้ม
const renderTrends = () => (
  <div className="space-y-6">
    <Card className="p-4 sm:p-6">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <LineChart className="h-6 w-6 mr-2 text-indigo-600" />
        <span>แนวโน้มการล็อกอิน</span>
      </h3>
      
      {/* จำกัดความกว้างของกราฟเส้น */}
      <div className="max-w-5xl mx-auto">
        <div className="h-64 md:h-80 mb-8">
          <Line data={dailyTrendData} options={{
            ...lineChartOptions,
            maintainAspectRatio: true,
            aspectRatio: window.innerWidth > 1280 ? 2.5 : 2,
            plugins: {
              ...lineChartOptions.plugins,
              title: {
                display: true,
                text: 'แนวโน้มการล็อกอินในช่วง 30 วันที่ผ่านมา',
                font: { size: 16 }
              }
            }
          }} />
        </div>
      </div>
      
      {/* สรุปข้อมูลสำคัญ */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
          <p className="text-sm text-gray-500">จำนวนการล็อกอินทั้งหมด</p>
          <p className="text-2xl font-bold text-gray-800">
            {stats.dailyStats?.reduce((sum, day) => sum + day.login_count, 0) || 0}
          </p>
        </div>
        <div className="bg-green-50 rounded-lg p-4 border border-green-100">
          <p className="text-sm text-gray-500">ค่าเฉลี่ยต่อวัน</p>
          <p className="text-2xl font-bold text-gray-800">
            {stats.dailyStats?.length ? 
              Math.round(stats.dailyStats.reduce((sum, day) => sum + day.login_count, 0) / stats.dailyStats.length) : 0}
          </p>
        </div>
        <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
          <p className="text-sm text-gray-500">สูงสุดต่อวัน</p>
          <p className="text-2xl font-bold text-gray-800">
            {stats.dailyStats?.reduce((max, day) => Math.max(max, day.login_count), 0) || 0}
          </p>
        </div>
        <div className="bg-orange-50 rounded-lg p-4 border border-orange-100">
          <p className="text-sm text-gray-500">เข้าระบบล่าสุด</p>
          <p className="text-xl font-bold text-gray-800">
            {formatDateForDisplay(stats.recentLogins?.[0]?.login_time)}
          </p>
        </div>
      </div>
    </Card>
    
    <Card className="p-4 sm:p-6">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <Clock className="h-6 w-6 mr-2 text-indigo-600" />
        <span>ช่วงเวลาที่มีการล็อกอินมากที่สุด</span>
      </h3>
      
      {/* จำกัดขนาดกราฟแท่ง */}
      <div className="max-w-4xl mx-auto">
        <div className="h-64 md:h-80">
          <Bar data={hourlyData} options={{
            ...barChartOptions,
            maintainAspectRatio: true,
            aspectRatio: 1.5,
            plugins: {
              ...barChartOptions.plugins,
              tooltip: {
                callbacks: {
                  title: function(tooltipItems) {
                    return tooltipItems[0].label; // แสดงเวลาตามรูปแบบ formatThaiHour
                  },
                  label: function(context) {
                    return `จำนวนล็อกอิน: ${context.parsed.y} ครั้ง`;
                  }
                }
              }
            },
            scales: {
              ...barChartOptions.scales,
              x: {
                ...barChartOptions.scales.x,
                ticks: {
                  ...barChartOptions.scales.x.ticks,
                  callback: function(value, index) {
                    return hourlyData.labels[index];
                  }
                }
              }
            }
          }} />
        </div>
      </div>
      
      {/* เพิ่มคำอธิบายและข้อมูลสำคัญ */}
      <div className="mt-6 bg-gray-50 p-4 rounded-lg max-w-4xl mx-auto">
        <p className="font-medium text-gray-700">ช่วงเวลาที่มีการใช้งานมากที่สุด</p>
        {stats.hourlyStats?.length > 0 ? (
          <p className="mt-2 text-gray-600">
            ช่วงเวลา {formatThaiHour(stats.hourlyStats.reduce((max, hour) => 
              hour.login_count > max.count ? {hour: hour.hour_of_day, count: hour.login_count} : max, 
              {hour: 0, count: 0}
            ).hour)} มีการเข้าสู่ระบบมากที่สุด คิดเป็น {((stats.hourlyStats.reduce((max, hour) => 
              hour.login_count > max.count ? {hour: hour.hour_of_day, count: hour.login_count} : max, 
              {hour: 0, count: 0}
            ).count / stats.hourlyStats.reduce((sum, hour) => sum + hour.login_count, 0)) * 100).toFixed(2)}% ของการล็อกอินทั้งหมด
          </p>
        ) : (
          <p className="mt-2 text-gray-500">ไม่พบข้อมูลการล็อกอินตามช่วงเวลา</p>
        )}
      </div>
    </Card>
  </div>
);

// 4. ปรับปรุงการแสดงข้อมูลอุปกรณ์
const renderDevices = () => (
  <div className="space-y-6">
    <Card className="p-4 sm:p-6">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <Smartphone className="h-6 w-6 mr-2 text-indigo-600" />
        <span>อุปกรณ์ที่ใช้งาน</span>
      </h3>
      
      {/* จำกัดความกว้างของกราฟวงกลม */}
      <div className="max-w-4xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="h-64 md:h-80">
            <Pie data={deviceData} options={{
              ...pieChartOptions,
              maintainAspectRatio: true,
              aspectRatio: 1,
              plugins: {
                ...pieChartOptions.plugins,
                title: {
                  display: true,
                  text: 'การกระจายตัวของอุปกรณ์',
                  font: { size: 16 }
                }
              }
            }} />
          </div>
          <div className="h-64 md:h-80">
            <Doughnut data={deviceData} options={{
              ...pieChartOptions,
              maintainAspectRatio: true,
              aspectRatio: 1,
              plugins: {
                ...pieChartOptions.plugins,
                title: {
                  display: true,
                  text: 'สัดส่วนอุปกรณ์ที่ใช้งาน',
                  font: { size: 16 }
                }
              }
            }} />
          </div>
        </div>
      </div>
    </Card>
    
    <Card className="p-4 sm:p-6">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <PieChart className="h-5 w-5 mr-2 text-indigo-600" />
        <span>ข้อมูลอุปกรณ์โดยละเอียด</span>
      </h3>
      
      {/* ตารางแสดงข้อมูลอุปกรณ์ที่ปรับปรุงสำหรับมือถือ */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 text-left">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">อุปกรณ์</th>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวน</th>
              <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เปอร์เซ็นต์</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {stats.deviceStats?.length > 0 ? (
              stats.deviceStats.map((device, index) => {
                const total = stats.deviceStats.reduce((acc, d) => acc + d.count, 0);
                const percentage = ((device.count / total) * 100).toFixed(2);
                return (
                  <tr key={device.device} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm font-medium text-gray-900">{device.device}</td>
                    <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">{device.count}</td>
                    <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex items-center">
                        <span className="mr-2">{percentage}%</span>
                        <div className="w-24 bg-gray-200 rounded-full h-2.5">
                          <div 
                            className="bg-indigo-600 h-2.5 rounded-full" 
                            style={{ width: `${percentage}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center text-sm text-gray-500">
                  ไม่พบข้อมูลอุปกรณ์
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* คำแนะนำสำหรับมือถือ */}
      <div className="mt-4 text-sm text-gray-500 text-center">
        <p>ข้อมูลนี้อ้างอิงจาก User Agent ของเบราว์เซอร์</p>
      </div>
    </Card>
  </div>
);

// 5. ปรับปรุงการแสดงข้อมูลล็อกอินล่าสุด
const renderRecentLogins = () => (
  <Card className="p-4 sm:p-6">
    <h3 className="text-xl font-semibold mb-4 flex items-center">
      <Clock className="h-6 w-6 mr-2 text-indigo-600" />
      <span>การล็อกอินล่าสุด</span>
    </h3>
    
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200 text-left">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 sm:px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">ผู้ใช้</th>
            <th scope="col" className="px-3 sm:px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">รหัส</th>
            <th scope="col" className="px-3 sm:px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">เวลา</th>
            <th scope="col" className="px-3 sm:px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">IP</th>
            <th scope="col" className="px-3 sm:px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">อุปกรณ์</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {stats.recentLogins?.length > 0 ? (
            stats.recentLogins.map((login, index) => (
              <tr key={login.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {login.username || '-'}
                </td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">
                  {login.member_id}
                </td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDateForDisplay(login.login_time)}
                </td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm text-gray-500">
                  {login.ip_address || '-'}
                </td>
                <td className="px-3 sm:px-6 py-3 sm:py-4 text-sm text-gray-500">
                  <div className="truncate max-w-xs" title={login.user_agent || '-'}>
                    {login.user_agent ? (
                      login.user_agent.length > 30 
                        ? `${login.user_agent.substring(0, 30)}...` 
                        : login.user_agent
                    ) : '-'}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-500">
                ไม่พบข้อมูลการล็อกอินล่าสุด
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

    {/* สำหรับอุปกรณ์มือถือ - ซ่อนในจอขนาดใหญ่ */}
    <div className="sm:hidden mt-4 text-sm text-gray-500 text-center">
      <p>เลื่อนซ้ายขวาเพื่อดูข้อมูลเพิ่มเติม</p>
    </div>

    {/* แสดงรายละเอียดด้านล่างสำหรับอุปกรณ์มือถือ */}
    <div className="md:hidden mt-6 space-y-4">
      <h4 className="font-medium text-gray-700">รายละเอียดการล็อกอินล่าสุด</h4>
      {stats.recentLogins?.slice(0, 5).map(login => (
        <div key={login.id} className="bg-gray-50 p-3 rounded-lg">
          <p className="font-medium">{login.username || `User #${login.member_id}`}</p>
          <p className="text-sm text-gray-500">
            เวลา: {formatDateForDisplay(login.login_time)}
          </p>
          <p className="text-sm text-gray-500">IP: {login.ip_address || '-'}</p>
          <details className="mt-1">
            <summary className="text-sm text-indigo-600 cursor-pointer">รายละเอียดอุปกรณ์</summary>
            <p className="text-xs text-gray-500 mt-1 break-words">{login.user_agent || '-'}</p>
          </details>
        </div>
      ))}
    </div>
  </Card>
);

// รูปแบบการแสดงผลสำหรับ mobile ที่ปรับปรุง
return (
  <div className="p-4 sm:p-8 bg-gray-50 min-h-screen">
    {/* ปรับปุ่มย้อนกลับให้เหมือนกับไฟล์อื่น */}
    <div className="flex justify-end items-center">
      <button
        onClick={() => navigate("/admin/dashboard")}
        className="flex gap-2 bg-none text-gray-600 px-4 py-2 mb-5 rounded-md hover:text-gray-800 transition-colors"
      >
        <ArrowLeft className="h-5 w-5" />
        ย้อนกลับ
      </button>
    </div>

    {/* แยกส่วนหัวเรื่องออกมาต่างหาก */}
    <div className="mb-8 flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-800">สถิติการล็อกอิน</h1>
        <p className="text-xl text-gray-600 mt-2">ข้อมูลสถิติการเข้าสู่ระบบของผู้ใช้งาน</p>
      </div>
    </div>

    {/* แท็บเมนู - ปรับให้รองรับ responsive และจัดตรงกลาง */}
    <div className="overflow-x-auto pb-2">
      <div className="tabs tabs-boxed flex justify-center whitespace-nowrap bg-white p-1 rounded-lg shadow-sm mb-6">
        <button
          className={`tab px-3 py-2 text-sm sm:px-4 ${activeTab === 'overview' ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}`}
          onClick={() => setActiveTab('overview')}
        >
          <span className="hidden sm:inline">ภาพรวม</span>
          <span className="sm:hidden">ภาพรวม</span>
        </button>
        <button
          className={`tab px-3 py-2 text-sm sm:px-4 flex items-center ${activeTab === 'users' ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}`}
          onClick={() => setActiveTab('users')}
        >
          <User className="h-4 w-4 sm:mr-2" />
          <span className="hidden sm:inline">ผู้ใช้งาน</span>
        </button>
        <button
          className={`tab px-3 py-2 text-sm sm:px-4 flex items-center ${activeTab === 'trends' ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}`}
          onClick={() => setActiveTab('trends')}
        >
          <Calendar className="h-4 w-4 sm:mr-2" />
          <span className="hidden sm:inline">แนวโน้ม</span>
        </button>
        <button
          className={`tab px-3 py-2 text-sm sm:px-4 flex items-center ${activeTab === 'devices' ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}`}
          onClick={() => setActiveTab('devices')}
        >
          <Smartphone className="h-4 w-4 sm:mr-2" />
          <span className="hidden sm:inline">อุปกรณ์</span>
        </button>
        <button
          className={`tab px-3 py-2 text-sm sm:px-4 flex items-center ${activeTab === 'recent' ? 'bg-indigo-600 text-white' : 'hover:bg-gray-100'}`}
          onClick={() => setActiveTab('recent')}
        >
          <Clock className="h-4 w-4 sm:mr-2" />
          <span className="hidden sm:inline">ล็อกอินล่าสุด</span>
        </button>
      </div>
    </div>

    {/* ปุ่มลบข้อมูล Log */}
    <div className="flex justify-end items-center mb-6">
      <button
        onClick={handleClearLogs}
        className="bg-red-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-700 transition-all flex items-center gap-2"
      >
        <RefreshCw className="h-5 w-5" />
        ลบข้อมูล Log ทั้งหมด
      </button>
    </div>

    {/* เนื้อหาหลัก */}
    {renderContent()}
  </div>
);
};

export default LoginStatistics;