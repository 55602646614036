import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'; 
import { AdminAuthProvider } from './context/AdminAuthContext';
import './App.css';
import Home from './home';
import LoginUsers from './components/Page/LoginUsers';
import Test from './components/Navbar';
import PageNotFound from './PageNotFound';
import Knowledge from './components/Knowledge';
import Navbar from './components/Navbar';
import Report from './components/Report';
import Landing from './components/LandingPage';
import Files from './components/Files';
import Handbook from './components/Handbook';
import Dashboard from './components/Page/Dashboard';
import LoginAdmin from './components/Page/LoginAdmin';
import UserManagement from './components/Page/UserManagement';
import DataManagement from './components/Page/DataManagement';
import AnnouncementManagement from './components/Page/AnnounManagement';
import ProblemStatus from './components/Page/ReportStatusManagement';
import CompanyFormsManagement from './components/Page/CompanyFormsManagement';
import FaqManagement from './components/Page/FaqManagement';
import KnowledgeManagement from './components/Page/KnowledgeManagement';
import FilesManager from './components/Page/FileManagement/FileManager';
import LoginStatistics from './components/Page/LoginStatistics';
import EventManagement from "./components/Page/EventManagement";
import HandbookManagement from './components/Page/HandbookManagement';
import ArticleDetail from './components/Page/ArticleDetail';
import RouteTracker from './utils/RouteTracker';
import ActivityLogsDashboard from './components/Page/ActivityLogsDashboard';
import useActivityLogging from './hooks/useActivityLogging';
import UserActivityTimeline from './components/Page/UserActivityTimeline';
import LogSummaryDashboard from './components/Page/LogSummaryDashboard';
import ApiTest from './components/Page/ApiTest';
import TokenTestPage from './components/Page/TokenTestPage';
import ForceChangePassword from './components/Page/ForceChangePassword';

// เพิ่มโค้ดนี้ใน index.js หรือ App.js เพื่อป้องกันการเชื่อมต่อ WebSocket ที่ไม่มีอยู่
// อาจช่วยลดข้อผิดพลาด WebSocket connection failed
if (process.env.NODE_ENV === 'development') {
  window.addEventListener('error', (event) => {
    if (event.message && event.message.includes('WebSocket')) {
      event.preventDefault();
      console.log('WebSocket connection suppressed in development mode');
    }
  });
}

const MainLayout = ({ children }) => (
  <div>
    <Navbar />
    <div>{children}</div>
  </div>
);

const AuthLayout = ({ children }) => <div>{children}</div>;

// สร้าง component เพื่อใช้ hook และเพิ่มใน App component
const ActivityTracker = () => {
  useActivityLogging();
  return null;
};

function App() {
  return (
    <Router>
      <RouteTracker />
      <ActivityTracker />
      <div className="App" style={{ fontFamily: "Noto Sans Thai, serif" }}>
        <Routes>
          {/* เส้นทางที่ต้องการ AdminAuthProvider */}
          <Route path="/admin/dashboard" element={<AdminAuthProvider><Dashboard /></AdminAuthProvider>} />
          <Route path="/admin/dashboard/*" element={<AdminAuthProvider><Dashboard /></AdminAuthProvider>} />
          <Route path="/admin/dashboard/users" element={<AdminAuthProvider><UserManagement /></AdminAuthProvider>} />
          <Route path="/admin/dashboard/data_management" element={<AdminAuthProvider><DataManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/announcement" element={<AdminAuthProvider><AnnouncementManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/problem-status" element={<AdminAuthProvider><ProblemStatus/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/company_forms_management" element={<AdminAuthProvider><CompanyFormsManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/faq_management" element={<AdminAuthProvider><FaqManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/login-statistics" element={<AdminAuthProvider><LoginStatistics/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/knowledge_management" element={<AdminAuthProvider><KnowledgeManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/event_management" element={<AdminAuthProvider><EventManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/files_management" element={<AdminAuthProvider><FilesManager/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/handbook_management" element={<AdminAuthProvider><HandbookManagement/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/activity-logs" element={<AdminAuthProvider><ActivityLogsDashboard/></AdminAuthProvider>} />
          <Route path="/admin/dashboard/activity-logs/summary" element={<AdminAuthProvider><LogSummaryDashboard/></AdminAuthProvider>}/>
          <Route path="/admin/dashboard/activity-logs/user/:memberId" element={<AdminAuthProvider><UserActivityTimeline/></AdminAuthProvider>}/>

                
          

          {/* เส้นทางที่ไม่ต้องการ AuthProvider */}
          <Route path="/adminlogin" element={<LoginAdmin />} />
          <Route path="/api-test" element={<ApiTest />} />
          <Route path="/token-test" element={<TokenTestPage />} />
          <Route path="/force-change-password" element={<ForceChangePassword />} />

          {/* เส้นทางที่ต้องการ AuthProvider */}
          <Route path="/" element={<AuthProvider><AuthLayout><Landing /></AuthLayout></AuthProvider>} />
          <Route path="/login" element={<AuthProvider><AuthLayout><LoginUsers /></AuthLayout></AuthProvider>} />
          <Route path="/home" element={<AuthProvider><MainLayout><Home /></MainLayout></AuthProvider>} />
          <Route path="/knowledge" element={<AuthProvider><MainLayout><Knowledge /></MainLayout></AuthProvider>} />
          <Route path="/handbook" element={<AuthProvider><MainLayout><Handbook /></MainLayout></AuthProvider>} />
          <Route path="/file_manager" element={<AuthProvider><MainLayout><Files /></MainLayout></AuthProvider>} />
          <Route path="/report" element={<AuthProvider><MainLayout><Report /></MainLayout></AuthProvider>} />
          <Route path="/knowledge/:slug" element={<AuthProvider><MainLayout><ArticleDetail /></MainLayout></AuthProvider>} />

          <Route path="/test" element={<AuthProvider><AuthLayout><Test /></AuthLayout></AuthProvider>} />

          {/* Page Not Found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;